/* eslint-disable */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable sonarjs/no-collapsible-if */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable sonarjs/no-use-of-empty-return-value */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable react/no-children-prop */
/* eslint-disable no-console */
/* eslint-disable sonarjs/no-duplicate-string */
//PS_AD-1
import React from 'react';
import { useState, useEffect, useContext, useRef } from 'react';
import PaymentSchedule from './PaymentSchedule';
import GeneralInformation from './GeneralInformation';
import PaymentCost from './PaymentCost';
import AgreementDetailsProps from './AgreementDetailsInterface';
import 'react-phone-number-input/style.css';
import {
  getPriceQuote,
  SendAutopayText,
  UpdateVehicleInfo,
  GetCustomer,
  getAgreementInfo,
  GenerateAgreementDoc,
  CreateAgreement,
  getPrintDocument,
  GetEmployeeID,
  GetRole,
  breakPackage,
  sendEPO,
  CoworkerRequired,
  getRetentionOffers,
  storeException,
} from '../../../api/user';
import { AgreementContext } from '../../../context/AgreementContext';
import { useHistory } from 'react-router';
import { ReactComponent as Alerticon } from '../../../assets/images/no-records-found.svg';
import StoreException from '../../Shared/StoreException';
import {
  Grid,
  Typography,
  RACTable,
  RACTableCell,
  RACTableRow,
  RACSelect,
  RACButton,
  RACRadio,
  RACCheckBox,
  RACModalCard,
  RACTextbox,
  CircularProgress,
} from '@rentacenter/racstrap';
//import '../../styles/agreement.css';
import VoidAgreement from '../AddCoCustomer/voidagreement';
import SecondFactor from '../TwoFactorPopup/TwoFactorPopup';
import AddCocustomer from '../AddCoCustomer/AddCocustomer';
import { agreementGlobalStyles } from '../../../JSstyles/agreementGlobalStyles';
import { autoPayStyles } from '../../../JSstyles/autoPayStyles';
import { useLocation, useParams } from 'react-router-dom';
import { AppRoute } from '../../../config/route-config';
import { SetPendingAgreementValue } from '../../../app/Routes';
import { ContainerContext } from '../../../app/App';
import { CustomPropInterface } from '../../../index';
import CustomerSignature from '../../Shared/CustomerSignature';
import { OriginContext } from '../../../context/OriginContext';
import { AMOrigin } from '../../../constants/constants';
import { FeatureFlagContext } from '../../../context/FeatureFlagContext';
import { cappedFreeTimeValidation } from './validationForCappedFreeTime';
import RACExchange from '../View Agreement/RACExchange';

interface Params {
  customerId: string;
  agreementId: string;
}
export default function AgreementDetails(props: any) {
  const childCompRef: any = useRef();
  const containerData = useContext(ContainerContext) as CustomPropInterface;
  const [CustomerCoverageData, setCustomerCoverageData] = useState();
  const { customerId, agreementId } = useParams<Params>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [coCustomerId, setcocustomerId] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [coCustomerName, setcoCustomerName] = useState('');
  const [IsAgreementVoided, setIsAgreementVoided] = useState(false);
  const [PaymentCostLoading, setPaymentCostLoading] = useState(true);
  let [storeExceptionEnable, setStoreExceptionEnable] = useState(false)
  let [initialAgrId, setInitialAgrId] = useState<any>()
  let [storeExceptionOpened, setstoreExceptionOpened] = useState(false)
  const CLUB_STATUS_ACTIVE_PENDING = ['A', 'P'];
  const pulls_data = (data) => {
    setcocustomerId(data.coCustomerId);
    setcoCustomerName(data.coCustomerName);
    props.func({
      coCustomerId: data.coCustomerId,
      coCustomerName: data.coCustomerName,
    });
    // eslint-disable-next-line no-console
    console.log('agreement details contexs', data); // LOGS DATA FROM CHILD (My name is Dean Winchester... &)
  };

  const pull_data = (data) => {
    setIsAgreementVoided(data);
    setIsAgreementVoidedStatus({ agreementVoidedStatus: true });
    // eslint-disable-next-line no-console
    console.log('agreementDetails void', data); // LOGS DATA FROM CHILD (My name is Dean Winchester... &)
  };
  const history = useHistory();
  const searchAM = useLocation().search;
  const classes = agreementGlobalStyles();
  const autoPayClassName = autoPayStyles();
  const AgreementDetailsContext = useContext(AgreementContext);
  let {
    AgreementCreateStatusContext,
    AgreementVoidStatusContext,
    setcontextAgrId,
    getCustomerCoverage,
    setgetCustomerCoverage,
    PendingAgreementDetails,
    agreementOptions,
    setAgrStoreNum,
    successDropdownValues,
    setsuccessDropdownValues,
    inventoryMapping,
    isSacDaysCompleted,
    SalesInfoResponse,
    brokenPackageData,
    resumebtnFeatureFlag,
    StoreExceptiontype,
    setStoreExceptiontype,
    setCappedFreeTimeValues,
    cappedFreeTimeValues,
    setCappedFreeTimeResponseValues,
    cappedFreeTimeResponseValues,
    setExchangeAgreement,
    setExchangeAgreementId,
    cappedFreeTimeApproverId,
    cappedFreeTimeAuthenticated,
    setCappedFreeTimeAuthenticated
  } = useContext(AgreementContext);
  const {SalesLeadPromo, setSalesLeadPromo} = useContext(AgreementContext);
  const { redirectOrigin } = useContext(OriginContext);
  const { featureFlagDetails } = useContext(FeatureFlagContext);
  const [PendingAgreementInputs, setPendingAgreementInputs] =
    PendingAgreementDetails;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ItemSearchValues, setItemSearchValues] = useState(
    AgreementDetailsContext
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [IsAgreementCreated, setIsAgreementCreated] =
    AgreementCreateStatusContext;
  const [IsAgreementVoidedStatus, setIsAgreementVoidedStatus] =
    AgreementVoidStatusContext;
  const BothCustomer = 'Both customer & co-customer';

  //PS_AD-1 creating object to store customer sign popup input values
  const CustomerSignObj = {
    PresentToSign: BothCustomer,
    SignPlaceChange: '',
    CustomerName: '',
    CoCustomerName: '',
    CustomerPhoneNumber: '',
    CoCustomerPhoneNumber: '',
    CustomerEmail: '',
    CoCustomerEmail: '',
    Reason: '',
    DigitalSignSelected: true,
    DigiSignChecked: true,
    ManualSignChecked: false,
    InStoreChecked: true,
    EmailChecked: false,
    EmailDisabled: false,
  };
  //PS_AD-1 creating object to store payment schedule css class names
  const HighlightCSSObj = {
    WeeklySchedule: false,
    BiWeeklySchedule: false,
    MonthlySchedule: false,
    SemiMonthlySchedule: false,
  };

  const CreateVehicleObj = {
    Make: '',
    Model: '',
    Year: '',
    VIN: '',
    VehicleLicensePlate: '',
  };
  //PS_AD-1 creating object to store all popup completion status
  const PopupCompleteObj = {
    VehicleInfoComplete: false,
    ConfirmDueDateComplete: false,
    CustomerSignComplete: false,
    PrintPopupComplete: false,
    SendAgreementComplete: false,
    AutoPayComplete: false,
    SMSAutheticationComplete: false,
    InitialPaymentComplete: false,
  };
  //PS_AD-1 creating object to store all popup open and close status
  const Popupstatus = {
    ProcessingFeePopupOpen: false,
    LoginOpen: false,
    VehicleInfoOpen: false,
    CreateVehicleOpen: false,
    ConfirmDueDateOpen: false,
    AutoPayOpen: false,
    CustomerSignOpen: false,
    ManualSignAuthenticationOpen: false,
    PrintPopupOpen: false,
    SMSAuthenticationOpen: false,
    SendAgreementOpen: false,
    InitialPaymentOpen: false,
  };
  const VehicleInfoObj = {
    SubmitDisabled: true,
    VehicleChkBox: false,
    ChkBoxIndex: null,
  };
  const CreateVehicleControlsObj = {
    CreateVehicleSubmit: false,
  };
  //PS_AD-1 creating object to store all Autopay Popup actions
  const AutopayObj = {
    AutopayChkBox: false,
    AutoPaySubmitDisable: true,
  };
  //PS_AD-1 creating object to store all SendAgreement popup actions
  const SendAgreemnetObj = {
    SendAgreementEmail: true,
    SendAgreementPrint: false,
    SendAgreementEmailTxt: '',
  };
  const AutopayCustomerInfoObj = {
    CustomerName: '',
    CustomerPhoneNumber: '',
  };
  //PS_AD-1 creating state variable to hold customerSignObj
  const search = useLocation().search;
  const salesLeadId: any = new URLSearchParams(search).get('salesLeadId');
  const [CustomerSign, setCustomerSign]: any = useState(CustomerSignObj);
  const [AutopayCustomerInfo, setAutopayCustomerInfo] = useState(
    AutopayCustomerInfoObj
  );
  //PS_AD-1 creating state variable to hold customerSignObj
  const [tempSchedule, settempSchedule] = useState(HighlightCSSObj);

  //PS_AD-1 creating state to hold Popupstatus
  const [PopupOpen, setPopupOpen] = useState(Popupstatus);

  const [AutoPayToggleStatus, setAutoPayToggleStatus] = useState(false);

  //PS_AD-1 creating state to hold PopupCompleteObj
  const [PopupCompleteStatus, setPopupCompleteStatus] =
    useState(PopupCompleteObj);

  const [VehicleInfoInput, setVehicleInfoInput] = useState(VehicleInfoObj);
  const [CreateVehicleControls, setCreateVehicleControls] = useState(
    CreateVehicleControlsObj
  );
  const [CreateVehicleInputs, setCreateVehicleInputs] =
    useState(CreateVehicleObj);
  //PS_AD-1 creating state variable to hold AutopayObj
  const [AutopayInput, setAutoPayInput] = useState(AutopayObj);
  //PS_AD-1 creating state variable to hold SendAgreemnetObj
  const [SendAgreementInput, setSendAgreementInput] =
    useState(SendAgreemnetObj);
  //PS_AD-1 creating state variable to store all input field edit enable or disable mode
  const [EditDisablemode, setEditDisablemode] =
    useState<AgreementDetailsProps['EditDisablemode']>(true);
  //PS_AD-1 creating state variable to store ext amount
  const [ExtAmt, setExtAmt] = useState();
  //PS_AD-1 creating state variable to store iframe URL
  const [IframeURL, setIframeURL] =
    useState<AgreementDetailsProps['IframeURL']>('');
  //PS_AD-1 creating state variable to store next button clicked status
  const [NextBtnDisabled, setNextBtnDisabled] = useState(true);
  const [NextBtnClicked, setNextBtnClicked] =
    useState<AgreementDetailsProps['NextBtnClicked']>(false);
  //PS_AD-1 creating state variable to enable tire agreement workflow
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [TireAgreement, setTireAgreement] =
    useState<AgreementDetailsProps['TireAgreement']>(false);
  const [PolicyEnabled, setPolicyEnabled] = useState(false);
  //PS_AD-1 creating state variable to store list of vehicle details
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [VehicleDetailsArray, setVehicleDetailsArray]: any = useState();
  const [VehcileInfoChxboxState, setVehcileInfoChxboxState]: any = useState([]);
  //below state created for demo purpose
  const [PaymentScheduleValues, setPaymentScheduleValues] = useState();
  const [InitialPaymentValues, setInitialPaymentValues] = useState<any>();
  const [TotalPaymentValues, setTotalPaymentValues] = useState();
  const [OtherFeeArray, setOtherFeeArray] = useState();
  const [NextDueDate, setNextDueDate] = useState('');
  const [PayScheduleValues, setPayScheduleValues] = useState();
  const [CreateAgrReqObj, setCreateAgrReqObj]: any = useState({});
  const [GetPriceQuoteRequest, setGetPriceQuoteRequest] = useState({});
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [GenerateDocReq, setGenerateDocReq] = useState({});
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [SendAutopayTextReq, setSendAutopayTextReq] = useState({});
  const [LoaderEnabled, setLoaderEnabled] = useState(false);
  const [SomethingWentWrong, setSomethingWentWrong] = useState(false);
  const [AgreementSchedule, setAgreementSchedule] = useState();
  const [EmployerPayschedule, setEmployerPayschedule] = useState();
  const [CalenderDuedate, setCalenderDuedate] = useState('');
  const [AgreementTotal, setAgreementTotal] = useState('');
  const [AgreementId, setAgreementId] = useState('');
  const [AgreementNumber, setAgreementNumber] = useState('');
  const [CustomerID, setCustomerID] = useState('');
  const [PrimaryCustomerInfo, setPrimaryCustomerInfo]: any = useState();
  const [AgrCustomerObj, setAgrCustomerObj]: any = useState([]);
  const [GlobalCustomerID, setGlobalCustomerID] = useState('');
  const [StoreNumber, setStoreNumber] = useState('');
  const [HiddenLoader, setHiddenLoader] = useState(false);
  const [CreateAgrProcessingPopup, setCreateAgrProcessingPopup] =
    useState(false);
  const [CreateAgrFailPopup, setCreateAgrFailPopup] = useState(false);
  const [SomethingWentWrongOpen, setSomethingWentWrongOpen] = useState(false);
  const [CurrentRole, setCurrentRole] = useState('');
  const [VoidEnabled, setVoidEnabled] = useState(false);
  const [InitialPaymentPaid, setInitialPaymentPaid] = useState(false);
  const [YearOfVehcileArray, setYearOfVehcileArray]: any = useState([
    { value: '2009', label: '2009' },
  ]);
  const [IsPendingAgreement, setIsPendingAgreement] = useState(false);
  const [PendingAgreementOptions, setPendingAgreementOptions] = useState();
  const [PendingAgreementDropdownValues, setPendingAgreementDropdownValues] =
    useState();
  const [ClubStatus, setClubStatus] = useState();
  const [ClubOriginStore, setClubOriginStore] = useState();
  const [IsLdwTiedWithClub, setIsLdwTiedWithClub] = useState();
  const [LDWstatus, setLDWstatus] = useState();
  const [DeliveryChargeDisable, setDeliveryChargeDisable] = useState(false);
  const [DeliveryChargeFeeValues, setDeliveryChargeFeeValues] = useState([
    '0.00',
  ]);
  const [CreateAgrProcessingMessage, setCreateAgrProcessingMessage] = useState(
    'We are creating the agreement for you!'
  );
  const [CreateAgrFirstFailMessage, setCreateAgrFirstFailMessage] =
    useState('Oops!!');
  const [CreateAgrFailMessage, setCreateAgrFailMessage] = useState(
    'Unable to create agreement at this moment'
  );
  const [documentGenerateCompleted, setdocumentGenerateCompleted] =
    useState(false);
  const [PreviousBtnDisabled, setPreviousBtnDisabled] = useState(false);
  const [PromoErrorOpen, setPromoErrorOpen] = useState(false);
  const [PromoCodeDropDownOptions, setPromoCodeDropDownOptions] = useState([
    { value: '', label: 'Select' },
  ]);
  const [PromoCodeValue, setPromoCodeValue] = useState('');
  const [PromoCodeDisable, setPromoCodeDisable] = useState(false);
  const [waiveLDWChecked, setWaiveLDWChecked] = useState(false);
  const [ErrorMsgPopupOpen, setErrorMsgPopupOpen] = useState(false);
  const [ApiErrorMsg, setApiErrorMsg] = useState('');
  const storeNumber = window.sessionStorage.getItem('storeNumber');
  const [employeeId, setEmployeeId] = useState("");
  //cappedFreeTime starts
  const [cappedFreeTimePopup, setCappedFreeTimePopup] = useState<boolean>(false);
  const [twoFactorAuth, setTwoFactorAuth] = useState<any>(false);
  const [proceedButtonLoader, setProceedButtonLoader] = useState<any>(false);
  const queryParams = new URLSearchParams(window.location.search);
  const isExchangeAgreement = queryParams.get('isExchange');
  const exchangeAgreementId = queryParams.get('exchangeagreementid');
  const [exchangeAgreementDueDate, setExchangeAgreementDueDate] = useState<any>('');
  const [oldAgreementTRTO, setOldAgreementTRTO] = useState<any>('');

  useEffect(() => {
    let employeeIdGA: any
    let loggedRole: any
    const GettingRole = async () => {
      const GetcurrentUser = await GetEmployeeID();
      console.log('GetcurrentUser', GetcurrentUser);
      if (GetcurrentUser?.status === 200) {
        const GetcurrentUserResponse = GetcurrentUser.data;
        const EmployeeId = GetcurrentUserResponse.employeeId;
        const GetMenuPayload = {
          coworkerId: EmployeeId,
        };
        const GetMenuDetails: any = await GetRole(GetMenuPayload);
        console.log('GetMenuDetails', GetMenuDetails);
        if (GetMenuDetails.status === 200) {
          const GetMenuDetailsResponse = GetMenuDetails.data;
          const currentRole = GetMenuDetailsResponse.coworkerProfile.role;
          setEmployeeId(GetMenuDetailsResponse?.coworkerProfile?.employeeId);

          console.log('currentRole', currentRole);
          setCurrentRole(currentRole);
          loggedRole = currentRole;
          employeeIdGA = GetMenuDetailsResponse?.coworkerProfile?.employeeId

        }
      }
    };
    if (containerData !== undefined) {
      console.log('containerData', containerData);
      setCurrentRole(containerData.GetRole());
      setEmployeeId(containerData?.GetEmployeeId());
      loggedRole = containerData.GetRole()
      employeeIdGA = containerData?.GetEmployeeId()

    } else {
      GettingRole();
    }
    window?.dataLayer?.push({
      'event': 'Loaded the Rental-Agreement Details page',
      'customerId': customerId,
      'storeNo': storeNumber,
      'agreementNo': agreementId,
      'employeeId': employeeIdGA,
      'role': loggedRole,
      'currentScreen': 'Rental-Agreement Details',
      'previousScreen': 'Agreement Item Search',
    });
    
    const updateCappedFreeTimeResponseValues = (key: any, value: any, setFunction: any) => {
      setFunction(prevState => ({
        ...prevState,
        [key]: value
      }));
    };
    async function getCappedFreeTimePercentage(){
      console.log(cappedFreeTimeValues, 'cappedFreeTimeValues');
      const payload = {
        agreementNumbers: null,
        percentage: cappedFreeTimeValues.cappedFreeTimePercentage.toString(),
        storeNumber: sessionStorage.getItem('storeNumber'), 
      }
      const cappedFreeTimeResponse = await getRetentionOffers(payload);
      console.log(cappedFreeTimeResponse, 'cappedFreeTimeResponse');
      if (cappedFreeTimeResponse?.status == 200 && cappedFreeTimeResponse?.data) {
        const monthlyRates = cappedFreeTimeResponse?.data?.monthSummary;
        const SMRRAmount = cappedFreeTimeResponse?.data?.smrPercentageAmount;
        console.log(monthlyRates, 'monthlyRates');
        updateCappedFreeTimeResponseValues('cappedFreeTimeSMRRAmount', SMRRAmount ? parseFloat(SMRRAmount) : 0, setCappedFreeTimeResponseValues);//Assign the smrPercentageAmount key
        updateCappedFreeTimeResponseValues('cappedFreeTimeRegular', monthlyRates?.ftOtherTotalAmount ? parseFloat(monthlyRates?.ftOtherTotalAmount) : 0, setCappedFreeTimeResponseValues);//Assign the ftOtherTotalAmount key	
        updateCappedFreeTimeResponseValues('cappedFreeTimeService', monthlyRates?.ftServiceTotalAmount ? parseFloat(monthlyRates?.ftServiceTotalAmount) : 0, setCappedFreeTimeResponseValues);//Assign the ftServiceTotalAmount key
        updateCappedFreeTimeResponseValues('cappedFreeTimeRetention', monthlyRates?.ftReiTotalAmount ? parseFloat(monthlyRates?.ftReiTotalAmount) : 0, setCappedFreeTimeResponseValues);//Assign the ftReiTotalAmount key
        updateCappedFreeTimeResponseValues('cappedFreeTimeInitPayment', monthlyRates?.ftDelTotalAmount ? parseFloat(monthlyRates?.ftDelTotalAmount) : 0, setCappedFreeTimeResponseValues);//Assign the ftDelTotalAmount key
        updateCappedFreeTimeResponseValues('cappedFreeTimePromo', monthlyRates?.ftPromoTotalAmount ? parseFloat(monthlyRates?.ftPromoTotalAmount) : 0, setCappedFreeTimeResponseValues);//Assign the ftPromoTotalAmount key
      }
      else {
          updateCappedFreeTimeResponseValues('cappedFreeTimeSMRRAmount',0, setCappedFreeTimeResponseValues);//Assign the smrPercentageAmount key
        updateCappedFreeTimeResponseValues('cappedFreeTimeRegular',0, setCappedFreeTimeResponseValues);//Assign the ftOtherTotalAmount key	
        updateCappedFreeTimeResponseValues('cappedFreeTimeService',0, setCappedFreeTimeResponseValues);//Assign the ftServiceTotalAmount key
        updateCappedFreeTimeResponseValues('cappedFreeTimeRetention',0, setCappedFreeTimeResponseValues);//Assign the ftReiTotalAmount key
        updateCappedFreeTimeResponseValues('cappedFreeTimeInitPayment',0, setCappedFreeTimeResponseValues);//Assign the ftDelTotalAmount key
        updateCappedFreeTimeResponseValues('cappedFreeTimePromo',0, setCappedFreeTimeResponseValues);
      }
    }
    console.log(cappedFreeTimeValues, 'cappedFreeTimeValues');
    cappedFreeTimeValues?.enableCappedFreeTimeFlag ? getCappedFreeTimePercentage() : null;

    if(isExchangeAgreement) {
      console.log('isExchangeAgreement', isExchangeAgreement);
      setExchangeAgreement(isExchangeAgreement)
    }
    if(exchangeAgreementId){
      console.log('exchangeAgreementId', exchangeAgreementId);
      setExchangeAgreementId(exchangeAgreementId);
    }
  }, []);




  useEffect(() => {
    if (
      ItemSearchValues.PendingAgreementDetails[0] === undefined &&
      ItemSearchValues.Itemsearch[0] !== undefined &&
      agreementId === undefined &&
      agreementOptions !== undefined
    ) {
      window.scrollTo(0, 0);
      const CreateAgreementReq: any = {};
      const Maxyear = new Date().getFullYear();
      const MinYear = Maxyear - 50;
      const VehicleYearArr: any = [{ value: '', label: 'Select' }];
      for (let val = Maxyear; val >= MinYear; val--) {
        const obj = {
          value: val.toString(),
          label: val.toString(),
        };
        VehicleYearArr.push(obj);
      }
      setYearOfVehcileArray(VehicleYearArr);
      if (ItemSearchValues !== undefined) {
        // eslint-disable-next-line no-console
        console.log('Itemsearch realese--->', ItemSearchValues);
      }
      CreateAgreementReq.coverages = {
        isLdwApplied: true,
        isClubApplied: true,
        isTirepolicyApplied: false,
      };
      const ItemSearchVal: any = ItemSearchValues.Itemsearch[0];
      setCustomerID(ItemSearchVal.customerId.toString());
      setStoreNumber(ItemSearchVal.storeNumber);
      // eslint-disable-next-line no-console
      console.log('RawItemSearchInput', ItemSearchVal);
      const GetPriceQuoteReq: any = {
        schedule: null,
        dueDate: '',
        storeNumber: ItemSearchVal.storeNumber,
        customerId: ItemSearchVal.customerId,
        agreementRate: ItemSearchVal.agreementRate,
        agreementTerm: ItemSearchVal.agreementTerm,
        isLDWapplied: true,
        isClubApplied: true,
        tireplusPolicy: ItemSearchVal.TireAgreementFlow ? true : false,
        sacTotal: ItemSearchVal.sacTotal,
        extensionDays: 0,
        inventoryInfo: ItemSearchVal.inventory,
        totalCost: ItemSearchVal.AgreementTotal,
        packageName: ItemSearchVal?.itemDescription?.toLowerCase()=='add-on package' ? 'Add-On Package' : undefined ,
        ...(ItemSearchValues?.isExchangeAgreement && { isExchangeAgreement: true }),
        ...(ItemSearchValues?.isExchangeAgreement && { parentAgreementId: parseInt(ItemSearchValues?.exchangeAgreementId) }),
      };

      // eslint-disable-next-line no-console
      console.log('RawItemSearchInput', ItemSearchVal);
      // eslint-disable-next-line no-console
      console.log('GetPriceReq', GetPriceQuoteReq);
      // eslint-disable-next-line no-console
      console.log('CreateAgreementReq', CreateAgreementReq);
      if (agreementOptions.storePromotions != undefined) {
        let PromoCodeOptionsArray: any = agreementOptions.storePromotions.map(
          (ele: any) => {
            return { value: ele.promotionCode, label: ele.promotionName };
          }
        );
        PromoCodeOptionsArray.splice(0, 0, {
          value: '',
          label: 'Select',
        });
        
        console.log('agreementOptions.customerPromotions', agreementOptions.customerPromotions);
        if (agreementOptions.customerPromotions != undefined && agreementOptions.customerPromotions.length > 0) {
          const customerPromotionsFiltered = agreementOptions.customerPromotions.filter((e: any) => {
            return !PromoCodeOptionsArray.some((promo: any) => promo.value === e.promotionCode);
          }).map((e: any) => {
            return { value: e.promotionCode, label: e.promotionName };
          });
          console.log('customerPromotions', customerPromotionsFiltered);
          PromoCodeOptionsArray.push(...customerPromotionsFiltered);
        }

        setPromoCodeDropDownOptions(PromoCodeOptionsArray);
      } else {
        setPromoCodeDisable(true);
      }

      if (SalesInfoResponse != undefined) {
        if (SalesInfoResponse.promoCode != undefined) {
          const PromoCodes = agreementOptions.storePromotions.map(
            (Obj: any) => {
              return Obj.promotionCode;
            }
          );
          if (PromoCodes.includes(SalesInfoResponse.promoCode)) {
            GetPriceQuoteReq.promoCode = SalesInfoResponse.promoCode;
            setPromoCodeValue(SalesInfoResponse.promoCode);
          } else {
            GetPriceQuoteReq.promoCode = null;
            setSalesLeadPromo(SalesInfoResponse.promoCode);
            setPromoErrorOpen(true);
          }
        }
      }
      const InitialPageLoad = async () => {
        const GetPriceQuoteResponse: any = await getPriceQuote(
          GetPriceQuoteReq
        );
        if (GetPriceQuoteResponse.status === 200) {
          if (GetPriceQuoteResponse?.data?.agreementTotalPayment?.totalCost) {
            setAgreementTotal(
              GetPriceQuoteResponse?.data?.agreementTotalPayment?.totalCost
            );
            GetPriceQuoteReq.totalCost =
              GetPriceQuoteResponse?.data?.agreementTotalPayment?.totalCost;
            CreateAgreementReq.agreementInfo = {
              totalCost: ItemSearchValues?.isExchangeAgreement ? ItemSearchValues?.addedItem?.agreementPricing?.exchangeAgreementTotal : ItemSearchVal.AgreementTotal,
            };
          }
          setIsLdwTiedWithClub(
            GetPriceQuoteResponse.data.storeCoverageStatus.isLdwTiedWithClub
          );
          setLDWstatus(
            GetPriceQuoteResponse.data.customerCoverages
              .previousAgreementsLdwStatus
          );
          const CustomerCoverageObj: any = {
            storeCoverageStatus: GetPriceQuoteResponse.data.storeCoverageStatus,
            clubInfo: GetPriceQuoteResponse.data.customerCoverages,
            GetPriceQuoteResponse: GetPriceQuoteResponse.data
          };
          setCustomerCoverageData(CustomerCoverageObj);
          setClubStatus(
            GetPriceQuoteResponse.data.customerCoverages.clubStatus
          );
          setClubOriginStore(
            GetPriceQuoteResponse.data.customerCoverages.clubOriginStore
          );
          if (
            CLUB_STATUS_ACTIVE_PENDING.includes(
              GetPriceQuoteResponse.data.customerCoverages.clubStatus
            ) ||
            GetPriceQuoteResponse.data.storeCoverageStatus
              .clubOnAgreementCreation === false
          ) {
            GetPriceQuoteReq.isClubApplied = false;
            CreateAgreementReq.coverages.isClubApplied = false;
          }
          if (
            GetPriceQuoteResponse.data.storeCoverageStatus.isLDWAllowed ===
            false
          ) {
            GetPriceQuoteReq.isLDWapplied = false;
            CreateAgreementReq.coverages.isLdwApplied = false;
          }
          if (
            GetPriceQuoteResponse.data.storeCoverageStatus.isLdwTiedWithClub ===
            true &&
            GetPriceQuoteReq.isLDWapplied === false
          ) {
            GetPriceQuoteReq.isClubApplied = false;
            CreateAgreementReq.coverages.isClubApplied = false;
          }
          if (
            ItemSearchVal.TireAgreementFlow === true &&
            GetPriceQuoteResponse.data.storeCoverageStatus.isPolicyAllowed ===
            true
          ) {
            GetPriceQuoteReq.tireplusPolicy = true;
            CreateAgreementReq.coverages.isTirepolicyApplied = true;
            setTireAgreement(true);
            setPolicyEnabled(true);
          }
          if (ItemSearchVal.TireAgreementFlow === true) {
            GetPriceQuoteReq.isLDWapplied = false;
            GetPriceQuoteReq.isClubApplied = false;
            CreateAgreementReq.coverages.isLdwApplied = false;
            CreateAgreementReq.coverages.isClubApplied = false;
          }
          if(GetPriceQuoteResponse.data.policyApplicable ===1)
              setPolicyEnabled(true);
        }
        if (
          GetPriceQuoteResponse !== undefined &&
          GetPriceQuoteResponse.status === 200
        ) {
          /* eslint-disable no-console */
          setEditDisablemode(false);
          const GetPriceQuoteBind: any = GetPriceQuoteResponse.data;
          setLoaderEnabled(false);
          console.log('GetPriceQuoteResponse----->', GetPriceQuoteBind);
          setExchangeAgreementDueDate(GetPriceQuoteBind?.exchangeAgreementInfo?.nextDueDate);
          setOldAgreementTRTO(GetPriceQuoteBind?.exchangeAgreementInfo?.totalCost);
          const PaymentScheduleVal = GetPriceQuoteBind.paymentSchedule;
          const InitialPaymentVal = GetPriceQuoteBind.agreementInitialPayment;
          const TotalPaymentVal = GetPriceQuoteBind.agreementTotalPayment;
          const RawDueDate = GetPriceQuoteBind.agreementSchedule.dueDate;
          const FeeArr = GetPriceQuoteBind.fees;
          GetPriceQuoteReq.fees = FeeArr.map((ele: any) => {
            return {
              feeAmount: ele.feeAmount,
              feeType: ele.feeType,
            };
          });
          const ConvFeesArr = FeeArr.map((ele: any) => {
            return {
              feeAmount: parseFloat(ele.feeAmount),
              feeType: ele.feeType,
              originalFeeAmount: ele.originalFeeAmount,
            };
          });
          const EmployerPaymentSchedule = GetPriceQuoteBind.employerPayschedule;

          if (
            GetPriceQuoteBind.agreementSchedule !== null &&
            GetPriceQuoteBind.agreementSchedule !== undefined
          ) {
            GetPriceQuoteReq.dueDate =
              GetPriceQuoteBind.agreementSchedule.dueDate;
            GetPriceQuoteReq.schedule =
              GetPriceQuoteBind.agreementSchedule.schedule;
          }

          setGetPriceQuoteRequest(GetPriceQuoteReq);
          setCalenderDuedate(RawDueDate);
          setPaymentScheduleValues(PaymentScheduleVal);
          setInitialPaymentValues(InitialPaymentVal);
          setTotalPaymentValues(TotalPaymentVal);
          setOtherFeeArray(ConvFeesArr);
          setAgreementSchedule(GetPriceQuoteBind.agreementSchedule);
          setEmployerPayschedule(EmployerPaymentSchedule);
          if (GetPriceQuoteBind.deliveryFees.deliveryFeesApplicable === 0) {
            setDeliveryChargeDisable(true);
          }
          setDeliveryChargeFeeValues(GetPriceQuoteBind.deliveryFees.feeAmounts);

          const RawDueDateArr = RawDueDate.split('/');
          console.log('DueDateArray--->', RawDueDateArr);
          console.log('Before Formation CreateAgreementRes --->', CreateAgreementReq);
          console.log('Before Formation ItemSearchVal --->', ItemSearchVal);
          const FormatedDuedate =
            RawDueDateArr[2] + '-' + RawDueDateArr[0] + '-' + RawDueDateArr[1];
          setCalenderDuedate(FormatedDuedate);
          const NxtDueDate = ConvertDueDate(RawDueDateArr);
          setNextDueDate(NxtDueDate);
          setPayScheduleValues(GetPriceQuoteBind.paymentSchedule);
          CreateAgreementReq.agreementInfo = {
            schedule:
              GetPriceQuoteBind.agreementSchedule.schedule === 'WK'
                ? 'WK'
                : GetPriceQuoteBind.agreementSchedule.schedule === 'BIWK'
                  ? 'BIWK'
                  : GetPriceQuoteBind.agreementSchedule.schedule === 'MON'
                    ? 'MON'
                    : GetPriceQuoteBind.agreementSchedule.schedule.includes(
                      'SEMI'
                    ) === true
                      ? GetPriceQuoteBind.agreementSchedule.schedule
                      : null,
            agreementType: ItemSearchVal.agreementType,
            // deliveryCharge: 0.0,
            storeNumber: ItemSearchVal.storeNumber,
            agreementSource: salesLeadId === null || undefined ? 'W' : 'I',
            salesPersonId: 98648,
            extensionDays: 0,
            competitor: ItemSearchVal.competitorId,
            duedate: '',
            range: null,
            agreementRate: ItemSearchVal.agreementRate,
            agreementTerm: ItemSearchValues?.isExchangeAgreement ? parseInt(ItemSearchValues?.addedItem?.agreementPricing?.exchangeAgreementTerm) : ItemSearchVal.agreementTerm,
            requestAutopay: false,
            sacPrice: ItemSearchValues?.isExchangeAgreement ? parseFloat(ItemSearchValues?.addedItem?.agreementPricing?.exchangeCashPrice) : ItemSearchVal.sacPrice,
            agreementDesc: ItemSearchVal.itemDescription,
            salesLeadId: salesLeadId,
            totalCost: ItemSearchValues?.isExchangeAgreement ? ItemSearchValues?.addedItem?.agreementPricing?.exchangeAgreementTotal : ItemSearchVal.AgreementTotal,
            ...(ItemSearchValues?.isExchangeAgreement && { isExchangeAgreement: '1' }),
            ...(ItemSearchValues?.isExchangeAgreement && { parentAgreementId: parseInt(ItemSearchValues?.exchangeAgreementId) }),
            // GetPriceQuoteResponse?.data?.agreementTotalPayment?.totalCost,
          };
          CreateAgreementReq.inventoryInfo = inventoryMapping.map(
            (ele: any) => {
              return {
                inventoryId: ele.inventoryId.inventoryId,
                inventoryPriceId: ele.inventoryId.inventoryPriceId,
              };
            }
          );
          CreateAgreementReq.fees = ConvFeesArr;
          const CustomerArray: any = ItemSearchVal.customer;

          console.log(
            'ItemSearchValues.CustomerInfoContext[0].CoCustomerId ',
            ItemSearchValues.CustomerInfoContext[0].CoCustomerId
          );

          console.log(
            'Create Agreement obj first creation',
            CreateAgreementReq
          );
          if (ItemSearchValues !== undefined) {
            console.log(
              'CustomerInformation in deatails component',
              ItemSearchValues.CustomerInfoContext[0]
            );
            setGlobalCustomerID(
              ItemSearchValues.CustomerInfoContext[0].GlobalCustomerID
            );
            const CustomerRawPhoneNumber =
              ItemSearchValues.CustomerInfoContext[0].CusotmerPhoneNumber;
            const phoneNumber = CustomerRawPhoneNumber.replace(/[^\d]/g, '');
            if (phoneNumber.length === 10) {
              // eslint-disable-next-line prettier/prettier
              const FormatphoneNumber = phoneNumber.replace(
                /(\d{3})(\d{3})(\d{4})/,
                '($1) $2-$3'
              );
              setAutopayCustomerInfo({
                ...AutopayCustomerInfo,
                CustomerName:
                  ItemSearchValues.CustomerInfoContext[0].CustomerName,
                CustomerPhoneNumber: FormatphoneNumber,
              });
              setSendAgreementInput({
                ...SendAgreementInput,
                SendAgreementEmailTxt:
                  ItemSearchValues.CustomerInfoContext[0].CustomerEmail,
              });
            } else {
              setAutopayCustomerInfo({
                ...AutopayCustomerInfo,
                CustomerName:
                  ItemSearchValues.CustomerInfoContext[0].CustomerName,
                CustomerPhoneNumber: CustomerRawPhoneNumber,
              });
              setSendAgreementInput({
                ...SendAgreementInput,
                SendAgreementEmailTxt:
                  ItemSearchValues.CustomerInfoContext[0].CustomerEmail,
              });
            }
          }
          console.log('Before Formation CreateAgreementRes --->', CreateAgreementReq)
          setCreateAgrReqObj(CreateAgreementReq);
          const GenerateAgrReq: any = {};
          GenerateAgrReq.signingUrlRequired = false;
          GenerateAgrReq.signers = [];
          GenerateAgrReq.documentType = [];
          GenerateAgrReq.returnURL = window.location.origin;
          setGenerateDocReq(GenerateAgrReq);
        } else if (GetPriceQuoteResponse.status == 400) {
          const ErrorMessage = GetPriceQuoteResponse?.data?.errors[0]?.error;
          setApiErrorMsg(ErrorMessage);
          setErrorMsgPopupOpen(true);
        } else {
          setSomethingWentWrong(true);
        }
      };
      InitialPageLoad();
    } else if (ItemSearchValues.PendingAgreementDetails[0] !== undefined) {
      setIsPendingAgreement(true);
      setEditDisablemode(true);
      setdocumentGenerateCompleted(true);
      setDeliveryChargeDisable(true);
      setIsAgreementVoided(IsAgreementVoidedStatus.agreementVoidedStatus);
      props.setItemSearchCompleted(true);
      console.log('Im in pending agreement mode', ItemSearchValues);
      const PendingAgreementDetails =
        ItemSearchValues.PendingAgreementDetails[0];
      console.log('PendingAgreementValues', PendingAgreementDetails);
      const PaymentScheduleVal = PendingAgreementDetails.paymentSchedule;
      const InitialPaymentVal = PendingAgreementDetails.agreementInitialPayment;
      const TotalPaymentVal = PendingAgreementDetails.agreementTotalPayment;
      const AgreementSchVal = PendingAgreementDetails.agreementSchedule;
      const RawDueDate = PendingAgreementDetails.agreementSchedule.dueDate;
      const RawDueDateArr = RawDueDate.split('/');
      console.log('DueDateArray--->', RawDueDateArr);
      const FormatedDuedate =
        RawDueDateArr[2] + '-' + RawDueDateArr[0] + '-' + RawDueDateArr[1];
      setCalenderDuedate(FormatedDuedate);

      //need to verify the logic
      const EmployerPaymentSchedule =
        PendingAgreementDetails.employerPayschedule.schedule !== null &&
          PendingAgreementDetails.employerPayschedule.daysPaid !== null
          ? PendingAgreementDetails.employerPayschedule
          : {};
      const GeneralInfoDropdownOptions =
        PendingAgreementDetails.agreementOption;
      const PendingGeneralInfoDropdownValue =
        PendingAgreementDetails.generalInformation;
      setPaymentScheduleValues(PaymentScheduleVal);
      setInitialPaymentValues(InitialPaymentVal);
      setTotalPaymentValues(TotalPaymentVal);
      setEmployerPayschedule(EmployerPaymentSchedule);
      setAgreementSchedule(AgreementSchVal);
      setPayScheduleValues(PaymentScheduleVal);
      setPendingAgreementOptions(GeneralInfoDropdownOptions);
      setPendingAgreementDropdownValues(PendingGeneralInfoDropdownValue);
      setAgreementTotal(PendingAgreementDetails.agreementTotal);
      setAgreementId(PendingAgreementDetails.agreementId);
      setAgreementNumber(PendingAgreementDetails.agreementNumber);
      setCustomerID(PendingAgreementDetails.customers.customer[0].customerId);
      if (PendingAgreementDetails.policyApplicable === 1) {
        setPolicyEnabled(true);
      }

      const AgreementCustomers = PendingAgreementDetails.customers;
      const Customerdata: any = AgreementCustomers.customer;
      const CoCustomer: any = AgreementCustomers.coCustomer;
      console.log('AgreementCustomers', AgreementCustomers);
      console.log('Customerdata', Customerdata);
      setPrimaryCustomerInfo(Customerdata[0]);
      setSendAgreementInput({
        ...SendAgreementInput,
        SendAgreementEmailTxt: Customerdata[0].emailAddress,
      });
      if (CoCustomer.length === 0) {
        const GenerateAgrReq: any = {};
        GenerateAgrReq.signingUrlRequired = false;
        GenerateAgrReq.signers = [];
        GenerateAgrReq.documentType = [];
        GenerateAgrReq.returnURL = window.location.origin;
        setCustomerSign({
          ...CustomerSign,
          CustomerName: Customerdata[0].customerName,
          CustomerPhoneNumber: Customerdata[0].phoneNumber,
          CustomerEmail: Customerdata[0].emailAddress,
          PresentToSign: 'Customer',
        });
        const CustomerObj = [
          {
            customerId: Customerdata[0].customerId,
            customerName: Customerdata[0].customerName,
            phoneNumber: Customerdata[0]?.phoneNumbers ? Customerdata[0]?.phoneNumbers : Customerdata[0]?.phoneNumber,
            emailId: Customerdata[0].emailAddress,
            priority: 1,
          },
        ];
        setAgrCustomerObj(CustomerObj);
        GenerateAgrReq.signers = CustomerObj;
        setGenerateDocReq(GenerateAgrReq);
      } else {
        if (CoCustomer[0].isDocumentGenerated == '1') {
          const GenerateAgrReq: any = {};
          GenerateAgrReq.signingUrlRequired = false;
          GenerateAgrReq.signers = [];
          GenerateAgrReq.documentType = [];
          GenerateAgrReq.returnURL = window.location.origin;
          setCustomerSign({
            ...CustomerSign,
            CustomerName: Customerdata[0].customerName,
            CustomerPhoneNumber: Customerdata[0].phoneNumber,
            CustomerEmail: Customerdata[0].emailAddress,
            CoCustomerName: CoCustomer[0].customerName,
            CoCustomerPhoneNumber: CoCustomer[0].phoneNumber,
            CoCustomerEmail: CoCustomer[0].emailAddress,
            PresentToSign: BothCustomer,
            BothCustomerSelected: true,
          });
          const CustomerObj = [
            {
              customerId: Customerdata[0].customerId,
              customerName: Customerdata[0].customerName,
              phoneNumber: Customerdata[0]?.phoneNumbers ? Customerdata[0]?.phoneNumbers : Customerdata[0]?.phoneNumber,
              emailId: Customerdata[0].emailAddress,
              priority: 1,
            },
            {
              customerId: CoCustomer[0].customerId,
              customerName: CoCustomer[0].customerName,
              phoneNumber: CoCustomer[0]?.phoneNumbers ? CoCustomer[0]?.phoneNumbers : CoCustomer[0]?.phoneNumber,
              emailId: CoCustomer[0].emailAddress,
              priority: 2,
            },
          ];
          setAgrCustomerObj(CustomerObj);
          GenerateAgrReq.signers = CustomerObj;
          setGenerateDocReq(GenerateAgrReq);
        } else {
          const GenerateAgrReq: any = {};
          GenerateAgrReq.signingUrlRequired = false;
          GenerateAgrReq.signers = [];
          GenerateAgrReq.documentType = [];
          GenerateAgrReq.returnURL = window.location.origin;
          setCustomerSign({
            ...CustomerSign,
            CustomerName: Customerdata[0].customerName,
            CustomerPhoneNumber: Customerdata[0].phoneNumber,
            CustomerEmail: Customerdata[0].emailAddress,
            PresentToSign: 'Customer',
          });
          const CustomerObj = [
            {
              customerId: Customerdata[0].customerId,
              customerName: Customerdata[0].customerName,
              phoneNumber: Customerdata[0]?.phoneNumbers ? Customerdata[0]?.phoneNumbers : Customerdata[0]?.phoneNumber,
              emailId: Customerdata[0].emailAddress,
              priority: 1,
            },
          ];
          setAgrCustomerObj(CustomerObj);
          GenerateAgrReq.signers = CustomerObj;
          setGenerateDocReq(GenerateAgrReq);
        }
      }
      setPopupCompleteStatus({
        ...PopupCompleteStatus,
        VehicleInfoComplete:
          PendingAgreementDetails.popupCompleted.vehicleInfoComplete,
        ConfirmDueDateComplete:
          PendingAgreementDetails.popupCompleted.confirmDueDateComplete,
        AutoPayComplete: PendingAgreementDetails.popupCompleted.autoPayComplete,
        CustomerSignComplete:
          PendingAgreementDetails.popupCompleted.customerSignComplete,
        SMSAutheticationComplete:
          PendingAgreementDetails.popupCompleted.SMSAutheticationComplete,
        PrintPopupComplete:
          PendingAgreementDetails.popupCompleted.printPopupComplete,
        SendAgreementComplete:
          PendingAgreementDetails.popupCompleted.customerSignComplete,
        InitialPaymentComplete:
          PendingAgreementDetails.popupCompleted.initialPaymentComplete,
      });
      if (
        PendingAgreementDetails.popupCompleted.initialPaymentComplete === true
      ) {
        setInitialPaymentPaid(true);
      }
      setLoaderEnabled(false);
      setNextBtnDisabled(false);
      setAutoPayToggleStatus(
        PendingAgreementDetails.generalInformation.isAutopayEnabled
      );
      setWaiveLDWChecked(
        PendingAgreementDetails?.generalInformation?.isLdwWaived
      );
    } else if (
      agreementId !== undefined &&
      ItemSearchValues.PendingAgreementDetails[0] === undefined
    ) {
      setCustomerID(customerId);
      setAgreementId(agreementId);
      const AgreementInfoLoad = async () => {
        const agrInfoResponse = await getAgreementInfo(agreementId);
        console.log('agreement info response in agr details ', agrInfoResponse);
        setdocumentGenerateCompleted(true);
        if (agrInfoResponse.status === 200) {
          const agreementInfo = agrInfoResponse.data;
          setAgreementNumber(
            agreementInfo.agreementInformation.agreementNumber
          );
          if (
            agreementInfo.agreementInformation.agreementStatusRefCode === 'VOID'
          ) {
            setIsAgreementVoided(true);
            setIsAgreementVoidedStatus({ agreementVoidedStatus: true });
          }
          const paymentSchedule: any = {
            weeklyRate: agreementInfo.agreementInformation.agreementWeeklyRate, //
            weeklyTerm: parseInt(
              agreementInfo.agreementInformation.agreementWeeklyTerm
            ), //
            biweeklyRate:
              agreementInfo.agreementInformation.agreementBiWeeklyRate, //
            biweeklyTerm: parseInt(
              agreementInfo.agreementInformation.agreementBiWeeklyTerm
            ), //
            semimonthlyRate:
              agreementInfo.agreementInformation.agreementSemiMonthlyRate, //
            semimonthlyTerm: parseInt(
              agreementInfo.agreementInformation.agreementSemiMonthlyTerm
            ), //
            monthlyRate:
              agreementInfo.agreementInformation.agreementMonthlyRate, //
            monthlyTerm: parseInt(
              agreementInfo.agreementInformation.agreementMonthlyTerm
            ), //
          };

          const agreementSchedule: any = {
            schedule: agreementInfo.agreementInformation.scheduleRefCode, //
            dueDate: agreementInfo.agreementInformation.dueDate, //
          };

          const agreementInitialPayment: any = {
            rate: agreementInfo.initialPayment.rate, //
            initialRateTax: '0.00', //
            ldw: agreementInfo.initialPayment.ldw, //
            ldwTax: '0.00', //
            policy: agreementInfo.initialPayment.policy, //
            policyTax: '0.00', //
            extensionAmount: agreementInfo.initialPayment.extensionAmount, //
            extensionAmountTax: '0.00', //
            otherFees: agreementInfo.initialPayment.other, //
            otherFeesTax: '0.00', //
            totalTax: agreementInfo.initialPayment.tax, //
            initialPayment: agreementInfo.initialPayment.initialPayment, //
          };

          const agreementTotalPayment: any = {
            initialPaymentTotal: agreementInfo.totalPayment.initialPayment, //
            regularPaymentsCount: agreementInfo.totalPayment.regularTerm, //
            regularPaymentsAmount: agreementInfo.totalPayment.regularPayment, //
            finalPayment: agreementInfo.totalPayment.finalPayment, //
            totalPaymentsCount: agreementInfo.totalPayment.totalTerm, //
            sacTotal: agreementInfo.totalPayment.cashPrice, //
          };

          const employerPayschedule: any = {
            schedule: agreementInfo.agreementInformation.paySchedule, //
            daysPaid: agreementInfo.agreementInformation.payDay, //
          };

          const fees: any[] = []; //

          const fee: any = {
            feeAmount: agreementInfo.initialPayment.other,
            feeType: 'OTHER',
          };

          fees.push(fee);

          const popupCompleted: any = {
            vehicleInfoComplete: true, //
            confirmDueDateComplete: true, //
            customerSignComplete:
              agreementInfo.agreementWorkflow.isAgreementSigned == '1', //
            printPopupComplete:
              agreementInfo.agreementWorkflow.isPrinted == '1', //
            sendAgreementComplete:
              agreementInfo.agreementWorkflow.isPrinted == '1', //
            autoPayComplete: true, //
            SMSAutheticationComplete:
              agreementInfo.agreementWorkflow.isAgreementSigned == '1', //
            initialPaymentComplete:
              agreementInfo.agreementWorkflow.isInitialPaymentPaid == '1',
          };

          const customerInfo = agreementInfo.agreementCustomer.filter(
            (element) => element.priority == '1'
          );
          const coCustomerInfo = agreementInfo.agreementCustomer.filter(
            (element) => element.priority == '2'
          );

          const customers: any = {
            customer: customerInfo.map((ele) => {
              const customer: any = {
                priority: ele.priority,
                customerName: ele.firstName + ' ' + ele.lastName,
                firstName: ele.firstName,
                lastName: ele.lastName,
                customerId: ele.customerId,
                emailAddress: ele.emailId,
                ssn: null,
                gcdid: null,
                phoneNumber: ele.phoneNumber,
              };

              return customer;
            }), //
            coCustomer: coCustomerInfo.map((ele) => {
              const coCustomer: any = {
                priority: ele.priority,
                customerName: ele.firstName + ' ' + ele.lastName,
                firstName: ele.firstName,
                lastName: ele.lastName,
                customerId: ele.customerId,
                emailAddress: ele.emailId,
                ssn: null,
                gcdid: null,
                phoneNumber: ele.phoneNumber,
              };

              return coCustomer;
            }), //
          };

          const promoCode = agreementInfo.agreementInformation.promoCode
            ? agreementInfo.agreementInformation.promoCode
            : '';
          const promoName = agreementInfo.agreementInformation.promoName
            ? agreementInfo.agreementInformation.promoName
            : '';

          const agreementOption: any = {
            agreementSource: [
              {
                value:
                  agreementInfo.agreementInformation.agreementSourceRefCode, //
                label: agreementInfo.agreementInformation.agreementSource, //
              },
            ],
            optServices: [
              {
                value: agreementInfo.agreementInformation.coverageTypeRefCode, //
                label: agreementInfo.agreementInformation.optServices, //
              },
            ],
            deliveryCharge: [
              {
                value: agreementInfo.agreementInformation.deliveryCharge,
                label: agreementInfo.agreementInformation.deliveryCharge,
              },
            ],
            salesPerson: [
              {
                value:
                  agreementInfo.agreementInformation.salesPersonId.toString(), //
                label: agreementInfo.agreementInformation.salesPerson, //
              },
            ],
            schedule: [
              {
                value: agreementInfo.agreementInformation.scheduleRefCode, //
                label: agreementInfo.agreementInformation.paymentSchedule, //
              },
            ],
            promoCode: [
              {
                value: promoCode, //
                label: promoName, //
              },
            ],
          };

          const generalInformation: any = {
            agreementType: agreementInfo.agreementInformation.agreementType, //
            agreementSourceValue:
              agreementInfo.agreementInformation.agreementSourceRefCode, //
            deliveryChargeValue: parseInt(
              agreementInfo.agreementInformation.deliveryCharge
            ).toString(), //
            daysExtensionValue:
              agreementInfo.agreementInformation.daysExtension, //
            optServicesValue:
              agreementInfo.agreementInformation.coverageTypeRefCode, //
            promoCodeValue: promoCode, //
            policyValue:
              agreementInfo.agreementInformation.policy !== 'N/A'
                ? 'RAC Tire Plus'
                : 'None',
            salesPersonValue:
              agreementInfo.agreementInformation.salesPersonId.toString(), //
            isAutopayEnabled:
              agreementInfo.agreementWorkflow.isAutopayEnabled == '1', //
            isLdwWaived:
              agreementInfo?.agreementInformation?.isLdwWaived == '1',
          };
          const agreementDetailsContext: any = {
            paymentSchedule,
            agreementSchedule,
            agreementInitialPayment,
            agreementTotalPayment,
            employerPayschedule,
            fees,
            policyApplicable:
              agreementInfo.agreementInformation.policy !== 'N/A' ? 1 : 0,
            popupCompleted,
            agreementId: agreementInfo.agreementInformation.agreementId,
            customers,
            agreementOption,
            generalInformation,
            agreementTotal: agreementInfo.totalPayment.totalCost,
          };
          //data set happening from here
          setIsPendingAgreement(true);
          setEditDisablemode(true);
          setDeliveryChargeDisable(true);
          props.setItemSearchCompleted(true);
          // console.log('Im in pending agreement mode', ItemSearchValues);
          console.log('PendingAgreementValues', agreementDetailsContext);
          const PaymentScheduleVal = agreementDetailsContext.paymentSchedule;
          const InitialPaymentVal =
            agreementDetailsContext.agreementInitialPayment;
          const TotalPaymentVal = agreementDetailsContext.agreementTotalPayment;
          const AgreementSchVal = agreementDetailsContext.agreementSchedule;
          const RawDueDate = agreementDetailsContext.agreementSchedule.dueDate;
          const RawDueDateArr = RawDueDate.split('/');
          console.log('DueDateArray--->', RawDueDateArr);
          const FormatedDuedate =
            RawDueDateArr[2] + '-' + RawDueDateArr[0] + '-' + RawDueDateArr[1];
          setCalenderDuedate(FormatedDuedate);

          //need to verify the logic
          const EmployerPaymentSchedule =
            agreementDetailsContext.employerPayschedule.schedule !== null &&
              agreementDetailsContext.employerPayschedule.daysPaid !== null
              ? agreementDetailsContext.employerPayschedule
              : {};
          const GeneralInfoDropdownOptions =
            agreementDetailsContext.agreementOption;
          const PendingGeneralInfoDropdownValue =
            agreementDetailsContext.generalInformation;
          setPaymentScheduleValues(PaymentScheduleVal);
          setInitialPaymentValues(InitialPaymentVal);
          setTotalPaymentValues(TotalPaymentVal);
          setEmployerPayschedule(EmployerPaymentSchedule);
          setAgreementSchedule(AgreementSchVal);
          setPayScheduleValues(PaymentScheduleVal);
          setPendingAgreementOptions(GeneralInfoDropdownOptions);
          setPendingAgreementDropdownValues(PendingGeneralInfoDropdownValue);
          setAgreementTotal(agreementDetailsContext.agreementTotal);
          setAgreementId(agreementDetailsContext.agreementId);
          setCustomerID(
            agreementDetailsContext.customers.customer[0].customerId
          );
          if (agreementDetailsContext.policyApplicable === 1) {
            setPolicyEnabled(true);
          }

          const AgreementCustomers = agreementDetailsContext.customers;
          const Customerdata: any = AgreementCustomers.customer;
          const CoCustomer: any = AgreementCustomers.coCustomer;
          setSendAgreementInput({
            ...SendAgreementInput,
            SendAgreementEmailTxt: Customerdata[0].emailAddress,
          });
          if (CoCustomer.length === 0) {
            const GenerateAgrReq: any = {};
            GenerateAgrReq.signingUrlRequired = false;
            GenerateAgrReq.signers = [];
            GenerateAgrReq.documentType = [];
            GenerateAgrReq.returnURL = window.location.origin;
            setCustomerSign({
              ...CustomerSign,
              CustomerName: Customerdata[0].customerName,
              CustomerPhoneNumber: Customerdata[0].phoneNumber,
              CustomerEmail: Customerdata[0].emailAddress,
              PresentToSign: 'Customer',
            });
            const Customerphonenumber = Customerdata[0].phoneNumber;
            const Convertedphonenumber = Customerphonenumber;
            const CustomerObj = [
              {
                customerId: Customerdata[0].customerId,
                customerName: Customerdata[0].customerName,
                phoneNumber: Convertedphonenumber,
                emailId: Customerdata[0].emailAddress,
                priority: 1,
              },
            ];
            setAgrCustomerObj(CustomerObj);
            GenerateAgrReq.signers = CustomerObj;
            setGenerateDocReq(GenerateAgrReq);
          } else {
            const GenerateAgrReq: any = {};
            GenerateAgrReq.signingUrlRequired = false;
            GenerateAgrReq.signers = [];
            GenerateAgrReq.documentType = [];
            GenerateAgrReq.returnURL = window.location.origin;
            setCustomerSign({
              ...CustomerSign,
              CustomerName: Customerdata[0].customerName,
              CustomerPhoneNumber: Customerdata[0].phoneNumber,
              CustomerEmail: Customerdata[0].emailAddress,
              CoCustomerName: CoCustomer[0].customerName,
              CoCustomerPhoneNumber: CoCustomer[0].phoneNumber,
              CoCustomerEmail: CoCustomer[0].emailAddress,
              PresentToSign: BothCustomer,
              BothCustomerSelected: true,
            });

            const CoCustomerphonenumber = CoCustomer[0].phoneNumber;
            const Convertedphonenumber = CoCustomerphonenumber;
            const CustomerObj = [
              {
                customerId: Customerdata[0].customerId,
                customerName: Customerdata[0].customerName,
                phoneNumber: Customerdata[0]?.phoneNumbers ? Customerdata[0]?.phoneNumbers : Customerdata[0]?.phoneNumber,
                emailId: Customerdata[0].emailAddress,
                priority: 1,
              },
              {
                customerId: CoCustomer[0].customerId,
                customerName: CoCustomer[0].customerName,
                phoneNumber: CoCustomer[0]?.phoneNumbers ? CoCustomer[0]?.phoneNumbers : CoCustomer[0]?.phoneNumber,
                emailId: CoCustomer[0].emailAddress,
                priority: 2,
              },
            ];
            setAgrCustomerObj(CustomerObj);
            GenerateAgrReq.signers = CustomerObj;
            setGenerateDocReq(GenerateAgrReq);
          }
          setPopupCompleteStatus({
            ...PopupCompleteStatus,
            VehicleInfoComplete:
              agreementDetailsContext.popupCompleted.vehicleInfoComplete,
            ConfirmDueDateComplete:
              agreementDetailsContext.popupCompleted.confirmDueDateComplete,
            AutoPayComplete:
              agreementDetailsContext.popupCompleted.autoPayComplete,
            CustomerSignComplete:
              agreementDetailsContext.popupCompleted.customerSignComplete,
            SMSAutheticationComplete:
              agreementDetailsContext.popupCompleted.SMSAutheticationComplete,
            PrintPopupComplete:
              agreementDetailsContext.popupCompleted.printPopupComplete,
            SendAgreementComplete:
              agreementDetailsContext.popupCompleted.customerSignComplete,
            InitialPaymentComplete:
              agreementDetailsContext.popupCompleted.initialPaymentComplete,
          });
          setLoaderEnabled(false);
          setNextBtnDisabled(false);
          setAutoPayToggleStatus(
            agreementDetailsContext.generalInformation.isAutopayEnabled
          );
          setWaiveLDWChecked(
            agreementDetailsContext?.generalInformation?.isLdwWaived
          );
        } else {
          setSomethingWentWrong(true);
        }
      };
      AgreementInfoLoad();
    } else {
      setSomethingWentWrong(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (
      CreateVehicleInputs.Make !== '' &&
      CreateVehicleInputs.Model !== '' &&
      CreateVehicleInputs.VIN !== '' &&
      CreateVehicleInputs.Year !== '' &&
      CreateVehicleInputs.VehicleLicensePlate !== ''
    ) {
      setCreateVehicleControls({
        ...CreateVehicleControls,
        CreateVehicleSubmit: false,
      });
    }
    if (
      CreateVehicleInputs.Make === '' ||
      CreateVehicleInputs.Model === '' ||
      CreateVehicleInputs.VIN === '' ||
      CreateVehicleInputs.Year === '' ||
      CreateVehicleInputs.VehicleLicensePlate === ''
    ) {
      setCreateVehicleControls({
        ...CreateVehicleControls,
        CreateVehicleSubmit: true,
      });
    }
    if (VehcileInfoChxboxState.includes(true)) {
      setVehicleInfoInput({ ...VehicleInfoInput, SubmitDisabled: false });
    }
    if (VehcileInfoChxboxState.includes(true) === false) {
      setVehicleInfoInput({ ...VehicleInfoInput, SubmitDisabled: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CreateVehicleInputs, VehcileInfoChxboxState]);


  useEffect(() => {
    if (
      AgreementId !== undefined &&
      AgreementId !== null &&
      AgreementId !== ''
    ) {
      setVoidEnabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AgreementId]);
  const ConvertDueDate = (RawDate: any) => {
    let DueDate = '';
    if (RawDate[0] === '01') {
      DueDate = 'Jan' + ' ' + RawDate[1] + ', ' + RawDate[2];
    } else if (RawDate[0] === '02') {
      DueDate = 'Feb' + ' ' + RawDate[1] + ', ' + RawDate[2];
    } else if (RawDate[0] === '03') {
      DueDate = 'Mar' + ' ' + RawDate[1] + ', ' + RawDate[2];
    } else if (RawDate[0] === '04') {
      DueDate = 'Apr' + ' ' + RawDate[1] + ', ' + RawDate[2];
    } else if (RawDate[0] === '05') {
      DueDate = 'May' + ' ' + RawDate[1] + ', ' + RawDate[2];
    } else if (RawDate[0] === '06') {
      DueDate = 'Jun' + ' ' + RawDate[1] + ', ' + RawDate[2];
    } else if (RawDate[0] === '07') {
      DueDate = 'Jul' + ' ' + RawDate[1] + ', ' + RawDate[2];
    } else if (RawDate[0] === '08') {
      DueDate = 'Aug' + ' ' + RawDate[1] + ', ' + RawDate[2];
    } else if (RawDate[0] === '09') {
      DueDate = 'Sep' + ' ' + RawDate[1] + ', ' + RawDate[2];
    } else if (RawDate[0] === '10') {
      DueDate = 'Oct' + ' ' + RawDate[1] + ', ' + RawDate[2];
    } else if (RawDate[0] === '11') {
      DueDate = 'Nov' + ' ' + RawDate[1] + ', ' + RawDate[2];
    } else if (RawDate[0] === '12') {
      DueDate = 'Dec' + ' ' + RawDate[1] + ', ' + RawDate[2];
    }
    return DueDate;
  };
  //PS_AD-6 In NextBtnClick based upon the popup completion status popup will be displayed
  const NextBtnClick = async () => {
    try {
      setNextBtnClicked(true);


      if (storeExceptionOpened) {
        const anyStoreExceTrue = Object.values(StoreExceptiontype).some(value => value == true);
        if (anyStoreExceTrue == true) {
          setStoreExceptionEnable(true)
        }
      }
      else {
        if (
          TireAgreement === true &&
          PopupCompleteStatus.ConfirmDueDateComplete === false
        ) {
          const TempCreateAgrReq: any = CreateAgrReqObj;
          setHiddenLoader(true);
          const GetCustomerResponse = await GetCustomer(CustomerID, StoreNumber);
          if (GetCustomerResponse.status === 200) {
            const GetCustomerResponseBind = GetCustomerResponse.data;
            const CustomerVehicles =
              GetCustomerResponseBind.GetCustomer.value.vehicles;
            if (CustomerVehicles !== undefined) {
              console.log('CustomerVehicles', CustomerVehicles);
              const CustomerVehiclesSort = CustomerVehicles.sort(function (a, b) {
                return (
                  parseInt(b.customerVehicleId) - parseInt(a.customerVehicleId)
                );
              });
              console.log('CustomerVehiclesSort', CustomerVehiclesSort);
              setVehicleDetailsArray(CustomerVehiclesSort);
              if (CustomerVehiclesSort !== undefined) {
                const FalseArray: any = new Array(
                  CustomerVehiclesSort.length
                ).fill(false);
                FalseArray[0] = true;
                TempCreateAgrReq.agreementInfo.vehicleId = parseInt(
                  CustomerVehiclesSort[0].customerVehicleId
                );
                setCreateAgrReqObj(TempCreateAgrReq);
                setVehcileInfoChxboxState(FalseArray);
              }
              setHiddenLoader(false);
            } else if (CustomerVehicles === undefined) {
              setHiddenLoader(false);
            }
          }

          setPopupOpen({ ...PopupOpen, VehicleInfoOpen: true });
        } else if (
          PopupCompleteStatus.ConfirmDueDateComplete === false &&
          TireAgreement === false
        ) {
          setPopupOpen({ ...PopupOpen, ConfirmDueDateOpen: true });
        } else if (
          PopupCompleteStatus.ConfirmDueDateComplete === true &&
          PopupCompleteStatus.AutoPayComplete === false
        ) {
          if(ItemSearchValues.isExchangeAgreement){
            setPopupOpen({
              ...PopupOpen,
              AutoPayOpen: false,
              CustomerSignOpen: true,
            });
            setPopupCompleteStatus({
              ...PopupCompleteStatus,
              AutoPayComplete: true,
            });
          }
          else {
            setPopupOpen({ ...PopupOpen, AutoPayOpen: true });
          }
        } else if (
          PopupCompleteStatus.AutoPayComplete === true &&
          PopupCompleteStatus.CustomerSignComplete === false &&
          PopupCompleteStatus.InitialPaymentComplete === false
        ) {
          setPopupOpen({ ...PopupOpen, CustomerSignOpen: true });
        } else if (
          PopupCompleteStatus.CustomerSignComplete === true &&
          PopupCompleteStatus.InitialPaymentComplete === false
        ) {
          setPopupOpen({ ...PopupOpen, InitialPaymentOpen: true });
        } else if (PopupCompleteStatus.InitialPaymentComplete === true) {
          history.push({
            pathname: `/agreement/rental/delivery/${CustomerID}/${AgreementId}`,
          });
          const navEvent = new PopStateEvent('popstate');
          window.dispatchEvent(navEvent);
        }
      }


    } catch {
      throw Error;
    }
  };
  const VehicleDetailsBind = () => {
    if (VehicleDetailsArray !== undefined) {
      return VehicleDetailsArray.map((value: any, index: any) => {
        return (
          <tr key={index}>
            <td>
              {VehicleInfoInput.ChkBoxIndex === index ? (
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  checked={VehicleInfoInput.VehicleChkBox}
                  onChange={() => SelectVehicle(index)}
                />
              ) : (
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  checked={false}
                  onChange={() => SelectVehicle(index)}
                />
              )}
            </td>
            <td>
              <a className="racpad-link" href="#">
                {value.vehicleMake}
              </a>
            </td>
            <td>{value.vehicleModel}</td>
            <td>{value.vehicleYear}</td>
            <td>{value.vehicleVin}</td>
          </tr>
        );
      });
    }
  };
  const SelectVehicle = (index: any) => {
    try {
      setVehicleInfoInput({
        ...VehicleInfoInput,
        SubmitDisabled: false,
        VehicleChkBox: true,
        ChkBoxIndex: index,
      });
    } catch {
      throw Error;
    }
  };
  const VehicleInfoSubmitClick = () => {
    try {
      setPopupCompleteStatus({
        ...PopupCompleteStatus,
        VehicleInfoComplete: true,
      });
      setPopupOpen({
        ...PopupOpen,
        VehicleInfoOpen: false,
        ConfirmDueDateOpen: true,
      });
    } catch {
      throw Error;
    }
  };
  const CreateVehicleClick = () => {
    try {
      setPopupOpen({
        ...PopupOpen,
        VehicleInfoOpen: false,
        CreateVehicleOpen: true,
      });
    } catch {
      throw Error;
    }
  };
  const CreateVehicleCancelClick = () => {
    try {
      setCreateVehicleInputs({
        ...CreateVehicleInputs,
        Make: '',
        Model: '',
        Year: '',
        VIN: '',
        VehicleLicensePlate: '',
      });
      setPopupOpen({
        ...PopupOpen,
        VehicleInfoOpen: true,
        CreateVehicleOpen: false,
      });
    } catch {
      throw Error;
    }
  };
  const CreateVehicleSubmitClick = async () => {
    try {
      const TempCreateAgrReq = CreateAgrReqObj;
      setHiddenLoader(true);
      const TempArr: any = [];
      if (VehicleDetailsArray !== undefined) {
        for (let i = 0; i <= VehicleDetailsArray.length - 1; i++) {
          const obj = {
            customerVehicleId: null,
            vehicleIndex: null,
            vehicleVin: VehicleDetailsArray[i].vehicleVin,
            vehicleMake: VehicleDetailsArray[i].vehicleMake,
            vehicleModel: VehicleDetailsArray[i].vehicleModel,
            vehicleYear: VehicleDetailsArray[i].vehicleYear,
            vehicleLicensePlate: VehicleDetailsArray[i].vehicleLicensePlate,
            vehPlanExpirationDate: null,
            vehFinancedFrom: null,
            vehMonthlyPayment: null,
            vehRegState: null,
            vehicleTagNumber: null,
          };
          TempArr.push(obj);
        }
      }

      const tempobj = {
        customerVehicleId: null,
        vehicleIndex: null,
        vehicleVin: CreateVehicleInputs.VIN,
        vehicleMake: CreateVehicleInputs.Make,
        vehicleModel: CreateVehicleInputs.Model,
        vehicleYear: CreateVehicleInputs.Year,
        vehicleLicensePlate: CreateVehicleInputs.VehicleLicensePlate,
        vehPlanExpirationDate: null,
        vehFinancedFrom: null,
        vehMonthlyPayment: null,
        vehRegState: null,
        vehicleTagNumber: null,
      };
      TempArr.push(tempobj);
      const UpdateVehiclePayload = {
        customerId: CustomerID,
        globalCustomerId: GlobalCustomerID,
        vehicles: TempArr,
      };
      console.log('UpdateVehiclePayload', UpdateVehiclePayload);
      const UpdateVehicleInfoResponse: any = await UpdateVehicleInfo(
        CustomerID,
        UpdateVehiclePayload
      );
      console.log('UpdateVehicleInfoResponse', UpdateVehicleInfoResponse);

      if (UpdateVehicleInfoResponse.status === 200) {
        const GetCustomerResponse = await GetCustomer(CustomerID, StoreNumber);
        console.log('GetCustomerResponse', GetCustomerResponse);
        setHiddenLoader(false);
        if (GetCustomerResponse.status === 200) {
          const GetCustomerData = GetCustomerResponse.data;
          const ReGeneratedVehciles =
            GetCustomerData.GetCustomer.value.vehicles;
          const ReGeneratedVehcilesSort = ReGeneratedVehciles.sort(function (
            a,
            b
          ) {
            return (
              parseInt(b.customerVehicleId) - parseInt(a.customerVehicleId)
            );
          });
          const FalseArray: any = new Array(
            GetCustomerData.GetCustomer.value.vehicles.length
          ).fill(false);
          FalseArray[0] = true;
          setVehcileInfoChxboxState(FalseArray);
          TempCreateAgrReq.agreementInfo.vehicleId = parseInt(
            ReGeneratedVehcilesSort[0].customerVehicleId
          );
          setVehicleDetailsArray(ReGeneratedVehcilesSort);
          setCreateAgrReqObj(TempCreateAgrReq);
        }
        setPopupOpen({
          ...PopupOpen,
          CreateVehicleOpen: false,
          VehicleInfoOpen: true,
        });
        setCreateVehicleInputs({
          ...CreateVehicleInputs,
          Make: '',
          Model: '',
          Year: '',
          VIN: '',
          VehicleLicensePlate: '',
        });
      }
    } catch {
      throw Error;
    }
  };
  const CreateVehicleYearOnchange = (e) => {
    const value = e.target.value;
    setCreateVehicleInputs({ ...CreateVehicleInputs, Year: value });
  };

  const CreateVehicleInputOnchange = (e: any) => {
    try {
      const FieldName = e.target.name;
      const FieldValue = e.target.value;
      if (FieldName === 'Make') {
        setCreateVehicleInputs({ ...CreateVehicleInputs, Make: FieldValue });
      } else if (FieldName === 'Model') {
        setCreateVehicleInputs({ ...CreateVehicleInputs, Model: FieldValue });
      } else if (FieldName === 'Year') {
        setCreateVehicleInputs({ ...CreateVehicleInputs, Year: FieldValue });
      } else if (FieldName === 'VIN') {
        setCreateVehicleInputs({ ...CreateVehicleInputs, VIN: FieldValue });
      } else if (FieldName === 'VehicleLicensePlate') {
        setCreateVehicleInputs({
          ...CreateVehicleInputs,
          VehicleLicensePlate: FieldValue,
        });
      }
    } catch {
      throw Error;
    }
  };


  function getActivePhoneNumber(phoneNumbers: any) {
    for (const phoneNumber of phoneNumbers) {
      switch (phoneNumber.phoneType) {
        case 'CELL':
          return phoneNumber.PhoneNumber;
        case 'HOME':
          return phoneNumber.PhoneNumber;
        case 'REF':
          return phoneNumber.PhoneNumber;
        case 'WORK':
          return phoneNumber.PhoneNumber;
        default:
          return phoneNumber.PhoneNumber

      }
    }
    return undefined;
  }
  //Confirm date popup actions handle if autopay not enabled before showing autopay popup here
  const ConfirmDueDateAction = async () => {
    try {
      props.setAgrDetailsCompleted(true);
      setPopupOpen({ ...PopupOpen, ConfirmDueDateOpen: false });
      setCreateAgrProcessingPopup(true);
      const CustomerArray = ItemSearchValues.Itemsearch[0].customer;
      if (
        ItemSearchValues.CustomerInfoContext[0].CoCustomerId !== undefined &&
        ItemSearchValues.CustomerInfoContext[0].CoCustomerId !== null &&
        ItemSearchValues.CustomerInfoContext[0].CoCustomerId !== '' &&
        CustomerArray.length < 2
      ) {
        const obj = {
          customerId: parseFloat(
            ItemSearchValues.CustomerInfoContext[0].CoCustomerId
          ),
          priority: 2,
        };
        CustomerArray.push(obj);
      }
      CreateAgrReqObj.customer = CustomerArray;
      CreateAgrReqObj.agreementInfo.deDecisionId = Number(ItemSearchValues.CustomerInfoContext[0].deDecisionId);
      console.log('exact Create agreement response -->', CreateAgrReqObj);
      if (SalesInfoResponse?.promoCode != undefined && SalesInfoResponse?.promoCode != null && SalesInfoResponse?.promoCode != '') {

        CreateAgrReqObj.agreementInfo.promoCode = SalesLeadPromo == undefined ? null : SalesInfoResponse?.promoCode;
      }
      if(CreateAgrReqObj.agreementInfo.agreementDesc.toUpperCase() == 'ADD-ON PACKAGE'){ 
        CreateAgrReqObj.agreementInfo.agreementDesc = 'Add-On Package'
      }
      const CreateAgreementApiRes = await CreateAgreement(CreateAgrReqObj);
      console.log('Create agreement response -->', CreateAgreementApiRes);
      const CreateAgreementResponse = CreateAgreementApiRes.data;
      if (CreateAgreementApiRes.status === 200) {
        setIsAgreementCreated({ agreementCreatedStatus: true });
        props.setWizardAgrId(CreateAgreementResponse.agreementId.toString());
        props.setAgrDetailsCompleted(true);
        setAgrStoreNum(CreateAgrReqObj?.agreementInfo?.storeNumber);
        setAgreementId(CreateAgreementResponse.agreementId.toString());
        initialAgrId = Number(CreateAgreementResponse.agreementId)
        setInitialAgrId(initialAgrId)
        setAgreementNumber(String(CreateAgreementResponse.agreementNumber));
        setcontextAgrId(CreateAgreementResponse.agreementId.toString());
        console.log('Agreement create final req', CreateAgrReqObj);
        setEditDisablemode(true);
        setDeliveryChargeDisable(true);
        setPopupCompleteStatus({
          ...PopupCompleteStatus,
          ConfirmDueDateComplete: true,
        });

        const AgreementCustomers = CreateAgreementResponse.customers;
        const Customerdata = AgreementCustomers.filter(
          (obj) => obj.priority === 1
        );
        const CustomerName =
          Customerdata[0].firstName + ' ' + Customerdata[0].lastName;
        const TempPrimaryCusInfo = {
          customerId: Customerdata[0].customerId.toString(),
          phoneNumber: Customerdata[0].phoneNumber,
          emailAddress: Customerdata[0].emailAddress,
          customerName: CustomerName,
        };
        setPrimaryCustomerInfo(TempPrimaryCusInfo);
        if(cappedFreeTimeApproverId){
          let storeExceptionPayload = {
            storeException : [{
            "identifierId": CreateAgreementResponse.agreementId,
            "identifierType": "AGREEMENT",
            "storeNumber": sessionStorage.getItem('storeNumber'),
            "exceptionTypeRefCode": "CFT",
            "exceptionText": "Days Extension Capped Free Time",
            "approverId": cappedFreeTimeApproverId.toString()
          }]};
          const storeExceptionResponse = await storeException(storeExceptionPayload);
          console.log('storeExceptionResponse', storeExceptionResponse);
        }
        if (AgreementCustomers.length === 1) {
          const AGreementDocObj: any = GenerateDocReq;
          setCustomerSign({
            ...CustomerSign,
            CustomerName: CustomerName,
            CustomerPhoneNumber: Customerdata[0].phoneNumber,
            CustomerEmail: Customerdata[0].emailAddress,
            PresentToSign: 'Customer',
          });

          console.log('SetStateCompleted');
          const CustomerObj = [
            {
              customerId: Customerdata[0].customerId.toString(),
              customerName: CustomerName,
              phoneNumber: Customerdata[0]?.phoneNumbers ? getActivePhoneNumber(Customerdata[0]?.phoneNumbers) ? getActivePhoneNumber(Customerdata[0]?.phoneNumbers) : Customerdata[0]?.phoneNumber : Customerdata[0]?.phoneNumber,
              emailId: Customerdata[0].emailAddress,
              priority: 1,
            },
          ];
          console.log('PayloadForm');
          setAgrCustomerObj(CustomerObj);
          AGreementDocObj.signers = CustomerObj;
          setGenerateDocReq(AGreementDocObj);
        } else {
          const AGreementDocObj: any = GenerateDocReq;
          const CoCustomer = AgreementCustomers.filter(
            (obj) => obj.priority === 2
          );
          const CoCustomerName =
            CoCustomer[0].firstName + ' ' + CoCustomer[0].lastName;
          setCustomerSign({
            ...CustomerSign,
            CustomerName: CustomerName,
            CustomerPhoneNumber: Customerdata[0].phoneNumber,
            CustomerEmail: Customerdata[0].emailAddress,
            CoCustomerName: CoCustomerName,
            CoCustomerPhoneNumber: CoCustomer[0].phoneNumber,
            CoCustomerEmail: CoCustomer[0].emailAddress,
            PresentToSign: BothCustomer,
            BothCustomerSelected: true,
          });
          const CustomerObj = [
            {
              customerId: Customerdata[0].customerId.toString(),
              customerName: CustomerName,
              phoneNumber: Customerdata[0]?.phoneNumbers ? getActivePhoneNumber(Customerdata[0]?.phoneNumbers) ? getActivePhoneNumber(Customerdata[0]?.phoneNumbers) : Customerdata[0]?.phoneNumber : Customerdata[0]?.phoneNumber,
              emailId: Customerdata[0].emailAddress,
              priority: 1,
            },
            {
              customerId: CoCustomer[0].customerId.toString(),
              customerName: CoCustomerName,
              phoneNumber: CoCustomer[0]?.phoneNumbers ? getActivePhoneNumber(CoCustomer[0]?.phoneNumbers) ? getActivePhoneNumber(CoCustomer[0]?.phoneNumbers) : CoCustomer[0]?.phoneNumber : CoCustomer[0]?.phoneNumber,
              emailId: CoCustomer[0].emailAddress,
              priority: 2,
            },
          ];
          setAgrCustomerObj(CustomerObj);
          AGreementDocObj.signers = CustomerObj;
          setGenerateDocReq(AGreementDocObj);
        }
        if (PopupCompleteStatus.AutoPayComplete === true) {
          const AutopayReq: any = SendAutopayTextReq;
          const AgrId = [parseInt(CreateAgreementResponse.agreementId)];
          AutopayReq.agreementIds = AgrId;
          console.log('SendTexttopay req', AutopayReq);
          const SendAutopayTextResponse = await SendAutopayText(AutopayReq);
          console.log(
            'Update autopay response in confirm-->',
            SendAutopayTextResponse
          );
        }
        const AgreementDocReq: any = GenerateDocReq;
        AgreementDocReq.identifier = CreateAgreementResponse.agreementId;
        AgreementDocReq.identifierType = 'AGR';
        AgreementDocReq.signatureType = 1;
        AgreementDocReq.isRemote = false;
        AgreementDocReq.manualSignatureReasonId = null;
        if (AgreementCustomers.length === 1) {
          const CustomerObj = [
            {
              customerId: Customerdata[0].customerId.toString(),
              customerName: CustomerName,
              phoneNumber:
                Customerdata[0].phoneNumber !== null
                  ? Customerdata[0].phoneNumber
                  : '1111111111',
              emailId:
                Customerdata[0].emailAddress !== null
                  ? Customerdata[0].emailAddress
                  : 'test@example.com',
              priority: 1,
            },
          ];
          AgreementDocReq.signers = CustomerObj;
        } else {
          const CoCustomer = AgreementCustomers.filter(
            (obj) => obj.priority === 2
          );
          const CoCustomerName =
            CoCustomer[0].firstName + ' ' + CoCustomer[0].lastName;

          const CustomerObj = [
            {
              customerId: Customerdata[0].customerId.toString(),
              customerName: CustomerName,
              phoneNumber:
                Customerdata[0].phoneNumber !== null
                  ? Customerdata[0].phoneNumber
                  : '1111111111',
              emailId:
                Customerdata[0].emailAddress !== null
                  ? Customerdata[0].emailAddress
                  : 'test@example.com',
              priority: 1,
            },
            {
              customerId: CoCustomer[0].customerId.toString(),
              customerName: CoCustomerName,
              phoneNumber:
                CoCustomer[0].phoneNumber !== null
                  ? CoCustomer[0].phoneNumber
                  : '1111111111',
              emailId:
                CoCustomer[0].emailAddress !== null
                  ? CoCustomer[0].emailAddress
                  : 'test@example.com',
              priority: 2,
            },
          ];
          AgreementDocReq.signers = CustomerObj;
        }
        console.log('AgreementDocReq in create agr', AgreementDocReq);
        setCreateAgrProcessingPopup(false);
        const anyStoreExceTrue = Object.values(StoreExceptiontype).some(value => value == true);
        debugger
        if (anyStoreExceTrue == true) {
          storeExceptionOpened = true
          setstoreExceptionOpened(storeExceptionOpened)
          setStoreExceptionEnable(true)
        } else {
          if (AutoPayToggleStatus !== true && !ItemSearchValues?.isExchangeAgreement) {
            setPopupOpen({
              ...PopupOpen,
              ConfirmDueDateOpen: false,
              AutoPayOpen: true,
            });
          } else {
            setPopupOpen({
              ...PopupOpen,
              ConfirmDueDateOpen: false,
              CustomerSignOpen: true,
            });
          }
        }


        setPreviousBtnDisabled(true);
        const GenerateAgreementDocRes = await GenerateAgreementDoc(
          AgreementDocReq
        );
        console.log(
          'First Generate Agreement Call response',
          GenerateAgreementDocRes
        );
        setdocumentGenerateCompleted(true);
        setPreviousBtnDisabled(false);
      } else if (CreateAgreementApiRes.status === 400) {
        const ErrorArray = CreateAgreementApiRes.data.errors;
        const CheckErrorArray = ErrorArray.filter(
          (obj: any) => obj.error == 'Inventory is not in rent ready status'
        );
        if (CheckErrorArray.length > 0) {
          setCreateAgrFirstFailMessage('Sorry!');
          setCreateAgrFailMessage('The inventory is already rented');
        }
        setCreateAgrProcessingPopup(false);
        setCreateAgrFailPopup(true);
      } else {
        setCreateAgrFirstFailMessage('Oops!!');
        setCreateAgrFailMessage('Unable to create agreement at this moment');
        setCreateAgrProcessingPopup(false);
        setCreateAgrFailPopup(true);
      }
    } catch {
      throw Error;
    }
  };
  const AutopayChkboxAction = () => {
    try {
      if (AutopayInput.AutopayChkBox === false) {
        setAutoPayInput({
          ...AutopayInput,
          AutopayChkBox: true,
          AutoPaySubmitDisable: false,
        });
      } else {
        setAutoPayInput({
          ...AutopayInput,
          AutopayChkBox: false,
          AutoPaySubmitDisable: true,
        });
      }
    } catch {
      throw Error;
    }
  };
  //AutoPayCloseClick function close the autopay popup and set the AutoPayToggleStatus into false
  const AutoPayCloseClick = () => {
    try {
      setPopupOpen({ ...PopupOpen, AutoPayOpen: false });
      setAutoPayInput({
        ...AutopayInput,
        AutopayChkBox: false,
        AutoPaySubmitDisable: true,
      });

      setAutoPayToggleStatus(false);
    } catch {
      throw Error;
    }
  };
  //AutoPayCloseClick close the autopay popup and reset the autopay input fields into default status
  const AutoPayNotnowClick = async () => {
    try {
      if (NextBtnClicked === false) {
        setPopupOpen({ ...PopupOpen, AutoPayOpen: false });
        setAutoPayInput({
          ...AutopayInput,
          AutopayChkBox: false,
          AutoPaySubmitDisable: true,
        });

        setAutoPayToggleStatus(false);
      } else {
        setAutoPayInput({
          ...AutopayInput,
          AutopayChkBox: false,
          AutoPaySubmitDisable: true,
        });
        setAutoPayToggleStatus(false);
        setPopupOpen({
          ...PopupOpen,
          AutoPayOpen: false,
          CustomerSignOpen: true,
        });
        setPopupCompleteStatus({
          ...PopupCompleteStatus,
          AutoPayComplete: true,
        });
      }
    } catch {
      throw Error;
    }
  };
  //AutoPaySubmitClick calling the Api to update the auto active and set the input into default status and set the popup complete status
  const AutoPaySubmitClick = () => {
    try {
      setPopupOpen({ ...PopupOpen, AutoPayOpen: false });
      setAutoPayInput({
        ...AutopayInput,
        AutopayChkBox: false,
        AutoPaySubmitDisable: true,
      });
      setAutoPayToggleStatus(true);

      setPopupCompleteStatus({ ...PopupCompleteStatus, AutoPayComplete: true });
      if (PopupCompleteStatus.ConfirmDueDateComplete === true) {
        const AutopayReq: any = SendAutopayTextReq;
        const AgrId = [parseInt(AgreementId)];
        AutopayReq.agreementIds = AgrId;
        console.log('Sendtextto request', AutopayReq);
        console.log('Autopay Req--->', AutopayReq);
        const UpdateAutopayResponse = SendAutopayText(AutopayReq);
        setPopupOpen({
          ...PopupOpen,
          AutoPayOpen: false,
          CustomerSignOpen: true,
        });
        console.log('Autopay response--->', UpdateAutopayResponse);
      } else if (PopupCompleteStatus.ConfirmDueDateComplete === false) {
        setPopupOpen({
          ...PopupOpen,
          AutoPayOpen: false,
        });
      }
    } catch {
      throw Error;
    }
  };

  const InitialPaymentCloseClick = () => {
    try {
      setPopupOpen({
        ...PopupOpen,
        InitialPaymentOpen: false,
      });
    } catch {
      throw Error;
    }
  };
  const Openlogin = (loginstate: any) => {
    try {
      if (loginstate === 'activated') {
        setPopupOpen({ ...PopupOpen, LoginOpen: true });
      }
    } catch {
      throw Error;
    }
  };

  const GeneralInfoInputsChanged = (RequestObj: any) => {
    const CreateAgreementReqObj: any = CreateAgrReqObj;
    CreateAgreementReqObj.agreementInfo.salesPersonId =
      RequestObj.SalespersonId !== undefined
        ? RequestObj.SalespersonId
        : CreateAgreementReqObj.agreementInfo.salesPersonId;
    CreateAgreementReqObj.agreementInfo.agreementSource =
      RequestObj.AgreementSourceId !== undefined
        ? RequestObj.AgreementSourceId
        : CreateAgreementReqObj.agreementInfo.agreementSource;
    setCreateAgrReqObj(CreateAgreementReqObj);
  };
  const SacChanged = (RequestObj: any) => {
    console.log('changed sac amount', RequestObj);
    const CreateAgreementReqObj: any = CreateAgrReqObj;
    const RecalculateReqObj: any = GetPriceQuoteRequest;
    CreateAgreementReqObj.agreementInfo.sacPrice =
      RequestObj.sacPrice !== undefined
        ? RequestObj.sacPrice
        : CreateAgreementReqObj.agreementInfo.sacPrice;
    RecalculateReqObj.sacTotal =
      RequestObj.sacPrice !== undefined
        ? RequestObj.sacPrice
        : RecalculateReqObj.sacTotal;
    setCreateAgrReqObj(CreateAgreementReqObj);
    setGetPriceQuoteRequest(RecalculateReqObj);
  };
  const OtherAmtChanged = (RequestObj: any) => {
    console.log('changed other amount', RequestObj);
    const CreateAgreementReqObj: any = CreateAgrReqObj;
    CreateAgreementReqObj.fees =
      RequestObj !== undefined ? RequestObj : CreateAgreementReqObj.fees;
    setCreateAgrReqObj(CreateAgreementReqObj);
  };
  const DropdownReset = () => {
    childCompRef.current.DropdownReset();
  };
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const RecalculateClicked = async (RequestObj: any) => {
    setEditDisablemode(true);
    setDeliveryChargeDisable(true);
    setPaymentCostLoading(true);
    console.log('Recalculate obj from child component', RequestObj);
    console.log('Recalculate obj in parent component', GetPriceQuoteRequest);
    const RecalculateReqObj: any = GetPriceQuoteRequest;
    const CreateAgreementReqObj: any = CreateAgrReqObj;
    let fees: any = RecalculateReqObj.fees;
    if (RequestObj.processingFee) {
      if (fees.filter((e) => e.feeType == 'PROC').length > 0) {
        const indexOfDuplicate = fees.findIndex(
          (arr) => arr.feeType === 'PROC'
        );
        fees[indexOfDuplicate] = {
          feeType: 'PROC',
          feeAmount: RequestObj.processingFee,
        };
      } else {
        fees.push({
          feeType: 'PROC',
          feeAmount: RequestObj.processingFee,
        });
      }
    }
    //checking delivery fee undefined check
    if (RequestObj.DeliveryCharge != undefined) {
      //checking deivery fee already present in request
      if (fees.filter((e) => e.feeType == 'DELI').length > 0) {
        //checking delivery fee has no value
        if (
          RequestObj.DeliveryCharge == '' ||
          RequestObj.DeliveryCharge == '0.00'
        ) {
          fees = fees.filter((e) => e.feeType != 'DELI');
        }
        //if delivery fee has any value and already present in request
        else {
          const indexOfDuplicate = fees.findIndex(
            (arr) => arr.feeType === 'DELI'
          );
          fees[indexOfDuplicate] = {
            feeType: 'DELI',
            feeAmount: RequestObj.DeliveryCharge,
          };
        }
      }
      //if delivery fee is not present in request
      else {
        if (
          RequestObj.DeliveryCharge != '' &&
          RequestObj.DeliveryCharge != '0.00'
        ) {
          fees.push({
            feeType: 'DELI',
            feeAmount: RequestObj.DeliveryCharge,
          });
        }
      }
    }
    RecalculateReqObj.fees = fees;
    RecalculateReqObj.dueDate =
      RequestObj.Duedate !== undefined
        ? RequestObj.Duedate
        : RecalculateReqObj.dueDate;
    RecalculateReqObj.extensionDays =
      RequestObj.ExtensionDays !== undefined
        ? RequestObj.ExtensionDays
        : RecalculateReqObj.extensionDays;
    RecalculateReqObj.schedule =
      RequestObj.Schedule !== undefined
        ? RequestObj.Schedule
        : RecalculateReqObj.schedule;
    RecalculateReqObj.isLDWapplied =
      RequestObj.optionalservice !== undefined
        ? RequestObj.optionalservice.isLDWapplied
        : RecalculateReqObj.isLDWapplied;
    RecalculateReqObj.isClubApplied =
      RequestObj.optionalservice !== undefined
        ? RequestObj.optionalservice.isClubApplied
        : RecalculateReqObj.isClubApplied;
    RecalculateReqObj.range =
      RequestObj.range !== undefined
        ? RequestObj.range
        : RecalculateReqObj.range;
    RecalculateReqObj.promoCode =
      RequestObj.PromoCode !== undefined
        ? RequestObj.PromoCode
        : RecalculateReqObj.promoCode;
    RecalculateReqObj.tireplusPolicy =
      RequestObj.PolicyApplied !== undefined
        ? RequestObj.PolicyApplied
        : RecalculateReqObj.tireplusPolicy;

    RequestObj.isLDWWaived != undefined
      ? ((RecalculateReqObj.isLDWWaived = RequestObj.isLDWWaived),
        (CreateAgreementReqObj.coverages.isLDWWaived = RequestObj.isLDWWaived))
      : null;

    CreateAgreementReqObj.agreementInfo.promoCode =
      RequestObj.PromoCode !== undefined
        ? RequestObj.PromoCode
        : CreateAgreementReqObj.agreementInfo.promoCode;
    CreateAgreementReqObj.agreementInfo.schedule =
      RequestObj.Schedule !== undefined
        ? RequestObj.Schedule
        : CreateAgreementReqObj.agreementInfo.schedule;
    CreateAgreementReqObj.agreementInfo.salesPersonId =
      RequestObj.SalespersonId !== undefined
        ? RequestObj.SalespersonId
        : CreateAgreementReqObj.agreementInfo.salesPersonId;
    CreateAgreementReqObj.agreementInfo.extensionDays =
      RequestObj.ExtensionDays !== undefined
        ? RequestObj.ExtensionDays
        : CreateAgreementReqObj.agreementInfo.extensionDays;
    CreateAgreementReqObj.agreementInfo.agreementSource =
      RequestObj.AgreementSourceId !== undefined
        ? RequestObj.AgreementSourceId
        : CreateAgreementReqObj.agreementInfo.agreementSource;
    CreateAgreementReqObj.agreementInfo.duedate =
      RequestObj.Duedate !== undefined
        ? RequestObj.Duedate
        : CreateAgreementReqObj.agreementInfo.duedate;
    CreateAgreementReqObj.agreementInfo.range =
      RequestObj.range !== undefined
        ? RequestObj.range
        : CreateAgreementReqObj.agreementInfo.range;
    if (RequestObj.optionalservice !== undefined) {
      CreateAgreementReqObj.coverages.isLdwApplied =
        RequestObj.optionalservice.isLDWapplied !== undefined
          ? RequestObj.optionalservice.isLDWapplied
          : CreateAgreementReqObj.coverages.isLdwApplied;
      CreateAgreementReqObj.coverages.isClubApplied =
        RequestObj.optionalservice.isClubApplied !== undefined
          ? RequestObj.optionalservice.isClubApplied
          : CreateAgreementReqObj.coverages.isClubApplied;
    }
    CreateAgreementReqObj.coverages.isTirepolicyApplied =
      RequestObj.PolicyApplied !== undefined
        ? RequestObj.PolicyApplied
        : CreateAgreementReqObj.coverages.isTirepolicyApplied;
    console.log(
      'Create agreement modified after recalculate',
      CreateAgreementReqObj
    );

    console.log(
      'Recalculate obj in recalculate btn click-->',
      RecalculateReqObj
    );
    const GetPriceQuoteRes = await getPriceQuote(RecalculateReqObj);
    setEditDisablemode(false);
    console.log('Recaculate getprice quote raw response', GetPriceQuoteRes);
    if (GetPriceQuoteRes !== undefined && GetPriceQuoteRes.status === 200) {
      /* eslint-disable no-console */
      setLoaderEnabled(false);
      setPaymentCostLoading(false);
      console.log(
        'GetPriceQuoteResponse inside setState----->',
        GetPriceQuoteRes
      );
      if (RequestObj.PolicyApplied !== undefined) {
        if (RequestObj.PolicyApplied === true) {
          setTireAgreement(true);
        } else if (RequestObj.PolicyApplied === false) {
          setTireAgreement(false);
        }
      }
      const GetPriceQuoteData = GetPriceQuoteRes.data;
      const PaymentScheduleVal = GetPriceQuoteData.paymentSchedule;
      const InitialPaymentVal = GetPriceQuoteData.agreementInitialPayment;
      const TotalPaymentVal = GetPriceQuoteData.agreementTotalPayment;
      const RawDueDate = GetPriceQuoteData.agreementSchedule.dueDate;
      const FeesArr = GetPriceQuoteData.fees;
      const FormatFeesArr = FeesArr.map((ele: any) => {
        return {
          feeAmount: parseFloat(ele.feeAmount),
          feeType: ele.feeType,
          originalFeeAmount: ele.originalFeeAmount,
        };
      });
      const ConvFeesArr = FeesArr.map((obj: any) => {
        return {
          feeAmount: parseFloat(obj.feeAmount),
          feeType: obj.feeType,
        };
      });
      if (GetPriceQuoteData.deliveryFees.deliveryFeesApplicable === 0) {
        setDeliveryChargeDisable(true);
      } else {
        setDeliveryChargeDisable(false);
      }
      CreateAgreementReqObj.agreementInfo.schedule =
        GetPriceQuoteData.agreementSchedule.schedule;
      CreateAgreementReqObj.agreementInfo.sacPrice = parseFloat(
        GetPriceQuoteData.agreementTotalPayment.sacTotal
      );
      CreateAgreementReqObj.agreementInfo.totalCost = ItemSearchValues?.isExchangeAgreement ? ItemSearchValues?.addedItem?.agreementPricing?.exchangeAgreementTotal ? ItemSearchValues?.addedItem?.agreementPricing?.exchangeAgreementTotal :ItemSearchValues?.Itemsearch[0]?.AgreementTotal : ItemSearchValues?.Itemsearch[0]?.AgreementTotal;
      // GetPriceQuoteData?.agreementTotalPayment?.totalCost;
      CreateAgreementReqObj.fees = ConvFeesArr;
      const RawDueDateArray = RawDueDate.split('/');
      const FormatedDuedate =
        RawDueDateArray[2] +
        '-' +
        RawDueDateArray[0] +
        '-' +
        RawDueDateArray[1];
      setOtherFeeArray(FormatFeesArr);
      setCalenderDuedate(FormatedDuedate);
      setPaymentScheduleValues(PaymentScheduleVal);
      setInitialPaymentValues(InitialPaymentVal);
      setTotalPaymentValues(TotalPaymentVal);
      setAgreementSchedule(GetPriceQuoteData.agreementSchedule);
      console.log(
        'Agreement schedule after drop down ',
        GetPriceQuoteData.agreementSchedule
      );
      const RawDueDateArr = RawDueDate.split('/');
      console.log('DueDateArray--->', RawDueDateArr);
      const NxtDueDate = ConvertDueDate(RawDueDateArr);
      setNextDueDate(NxtDueDate);
      setAgreementTotal(GetPriceQuoteData?.agreementTotalPayment?.totalCost);
      setPayScheduleValues(GetPriceQuoteData.paymentSchedule);
    } else if (GetPriceQuoteRes.status === 400) {
      setPaymentCostLoading(false);
      const ErrorMessage = GetPriceQuoteRes.data.errors[0].error;
      setCreateAgrFirstFailMessage(ErrorMessage);
      setCreateAgrFailMessage('Please update and try again');
      setCreateAgrFailPopup(true);
      setEditDisablemode(false);
      DropdownReset();
    } else {
      setPaymentCostLoading(false);
      setSomethingWentWrongOpen(true);
      setEditDisablemode(false);
      DropdownReset();
    }
    console.log(
      'Create Agreement Obj after recalculate click',
      CreateAgreementReqObj
    );
    setCreateAgrReqObj(CreateAgreementReqObj);
  };
  const PreviousBtnClick = () => {
    console.log('Previous btn clicked');
    if (redirectOrigin === AMOrigin) {
      history.push({
        pathname: `${AppRoute.AMCustomer}/${CustomerID}`,
      });
      return;
    }
    if(ItemSearchValues?.isExchangeAgreement) {
      history.push(`/agreement/rental/itemsearch/${CustomerID}?coCustomerId=N&isExchange=1&agreementId=${ItemSearchValues?.exchangeAgreementId}`);
    }
    else {
      if (AgreementId === '' && origin !== 'am-customer') {
        history.push({
          pathname: `/agreement/rental/itemsearch/${CustomerID}?coCustomerId=N`,
        });
      } else if (AgreementId !== '' && origin !== 'am-customer') {
        history.push({
          pathname: `/agreement/rental/itemsearch/${CustomerID}/${AgreementId}?coCustomerId=N`,
        });
      }
    }

    // communicate to Routes that URL has changed//
    const navEvent = new PopStateEvent('popstate');
    window.dispatchEvent(navEvent);
  };

  const InitialPaymentOnclick = () => {

    if(isExchangeAgreement == '1'){
      history.push({
        pathname: `/payment1/paymentinformation/${CustomerID}/${AgreementId}`,
        search: `exchangeAgreementId=${exchangeAgreementId}`
      });
    }
    else if (ItemSearchValues?.isExchangeAgreement) {
      history.push({
        pathname: `/payment1/paymentinformation/${CustomerID}/${AgreementId}`,
        search: `exchangeAgreementId=${ItemSearchValues?.exchangeAgreementId}`
      });
    }
    else {
      history.push({
        pathname: `/payment1/paymentinformation/${CustomerID}/${AgreementId}`,
      });
    }
  };
  const InitialPaymentNoBtnclick = () => {
    setPopupOpen({ ...PopupOpen, InitialPaymentOpen: false });
  };
  const HideLoader = () => {
    return (
      <Grid
        style={{
          position: 'fixed',
          background: '#f7f5f5',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          opacity: 0.6,
          zIndex: 2000,
          textAlign: 'center',
          margin: '0px 0px',
        }}
      >
        <Grid
          style={{
            display: 'block',
            position: 'fixed',
            zIndex: 9999999,
            top: '40%',
            right: '50%',
          }}
        >
          <CircularProgress />
        </Grid>
      </Grid>
    );
  };
  const CreateAgreementProcessingPopup = () => {
    return (
      <div
        id="initialpayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={classes.textCenter}>
          <Typography className={classes.formLabel}>Please wait...</Typography>
          <Typography className={classes.formLabel}>
            {CreateAgrProcessingMessage}
          </Typography>
          <CircularProgress style={{ marginTop: '30px' }} size={25} />
        </Grid>
      </div>
    );
  };
  const CreateAgreementFailPopup = () => {
    return (
      <div
        id="initialpayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={classes.textCenter}>
          <Typography className={classes.formLabel}>
            {CreateAgrFirstFailMessage}
          </Typography>
          <Typography className={classes.formLabel}>
            {' '}
            {CreateAgrFailMessage}
          </Typography>
        </Grid>
        <Grid
          item
          md={12}
          className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
        >
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => setCreateAgrFailPopup(false)}
          >
            Ok
          </RACButton>
        </Grid>
      </div>
    );
  };
  const SomethingWentWrongPopup = () => {
    return (
      <div
        id="initialpayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={classes.textCenter}>
          <Alerticon></Alerticon>

          <Typography className={classes.formLabel}>
            Something went wrong!
          </Typography>
        </Grid>
        <Grid
          item
          md={12}
          className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
        >
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => setSomethingWentWrongOpen(false)}
          >
            Ok
          </RACButton>
        </Grid>
      </div>
    );
  };
  const ErrorMsgPopup = () => {
    return (
      <div
        id="initialpayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={classes.textCenter}>
          <Alerticon></Alerticon>
          <Typography className={classes.formLabel}>{ApiErrorMsg}</Typography>
        </Grid>
        <Grid
          item
          md={12}
          className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
        >
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => {
              history.push({
                pathname: `/dashboard`,
              });
            }}
          >
            Ok
          </RACButton>
        </Grid>
      </div>
    );
  };

  const ManualSignOptions = [
    {
      value: '',
      label: 'Select',
    },
    {
      value: '3',
      label: 'Technology issue - Digital Signing',
    },
    {
      value: '2',
      label: 'Technology issue - Customer',
    },
    {
      value: '1',
      label: 'Customer requires Paper Signature',
    },
  ];

  const VehicleHeadArr = [
    {
      displayName: '',
    },
    {
      displayName: 'Make',
    },
    {
      displayName: 'Model',
    },
    {
      displayName: 'Year',
    },
    {
      displayName: 'Vin#',
    },
  ];
  const VehicleInfoChxboxOnchnage = (index: any) => {
    console.log('VehicleInfoChxboxOnchnage', index);
    const TempCreateAgrReq = CreateAgrReqObj;
    const TempChkboxArr: any = new Array(VehcileInfoChxboxState.length).fill(
      false
    );
    TempChkboxArr[index] = true;
    setVehcileInfoChxboxState(TempChkboxArr);
    TempCreateAgrReq.agreementInfo.vehicleId = parseInt(
      VehicleDetailsArray[index].customerVehicleId
    );
    setCreateAgrReqObj(TempCreateAgrReq);
    setVehicleInfoInput({ ...VehicleInfoInput, SubmitDisabled: false });
  };
  const VehcileHeadTable = () => (
    <>
      {VehicleHeadArr.map((col, index) => {
        return <RACTableCell key={index}>{col.displayName}</RACTableCell>;
      })}
    </>
  );
  const VehicleBodyTable = () => (
    <>
      {VehicleDetailsArray.map((col, index) => {
        return (
          <RACTableRow key={index} hover backgroundColor="white">
            <RACTableCell>
              {
                <RACRadio
                  value=""
                  checked={VehcileInfoChxboxState[index]}
                  onChange={() => VehicleInfoChxboxOnchnage(index)}
                />
              }
            </RACTableCell>
            <RACTableCell>{col.vehicleMake}</RACTableCell>
            <RACTableCell>{col.vehicleModel}</RACTableCell>
            <RACTableCell>{col.vehicleYear}</RACTableCell>
            <RACTableCell>{col.vehicleVin}</RACTableCell>
          </RACTableRow>
        );
      })}
    </>
  );
  const ConfirmDueDateNoClick = () => {
    setNextBtnClicked(false);
    setPopupOpen({ ...PopupOpen, ConfirmDueDateOpen: false });
  };
  const ConfirmDueDate = () => {
    return (
      <Grid className="">
        <Grid className={classes.textCenter}>
          <Typography>
            Please confirm first renewal payment date{' '}
            <Typography className={`${classes.mt2} ${classes.fontBold}`}>
              {NextDueDate}
            </Typography>
          </Typography>
        </Grid>
        <Grid className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            variant="outlined"
            color="primary"
            onClick={() => ConfirmDueDateNoClick()}
          >
            No
          </RACButton>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => ConfirmDueDateAction()}
          >
            Yes
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  const CreateVehiclePopup = () => {
    return (
      <Grid
        className={classes.ps3}
        id="Customer-Signature"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid container spacing={2}>
          <Grid item md={6}>
            <RACTextbox
              type="text"
              inputlabel="Make"
              maxlength={14}
              id="vehicle_make"
              required={true}
              name="Make"
              value={CreateVehicleInputs.Make}
              OnChange={(e: any) => CreateVehicleInputOnchange(e)}
              isCurrency={false}
            />
          </Grid>
          <Grid item md={6}>
            <RACTextbox
              required={true}
              name="Model"
              value={CreateVehicleInputs.Model}
              OnChange={(e) => CreateVehicleInputOnchange(e)}
              isCurrency={false}
              inputlabel="Model"
              type="text"
              maxlength={14}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <RACSelect
              inputLabel="Year"
              options={YearOfVehcileArray}
              defaultValue={CreateVehicleInputs.Year}
              required={true}
              name="Year"
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                CreateVehicleYearOnchange(e);
              }}
            />
          </Grid>
          <Grid item md={6}>
            <RACTextbox
              required={true}
              name="VIN"
              value={CreateVehicleInputs.VIN}
              OnChange={(e) => CreateVehicleInputOnchange(e)}
              isCurrency={false}
              inputlabel="VIN#"
              type="text"
              maxlength={14}
            />
          </Grid>
          <Grid item md={6}>
            <RACTextbox
              required={true}
              name="VehicleLicensePlate"
              value={CreateVehicleInputs.VehicleLicensePlate}
              OnChange={(e) => CreateVehicleInputOnchange(e)}
              isCurrency={false}
              inputlabel="Vehicle License Plate"
              type="text"
              maxlength={14}
            />
          </Grid>
        </Grid>
        <Grid
          className={`${classes.popupFooter} ${classes.pb4} ${classes.px3}`}
        >
          <RACButton
            variant="outlined"
            color="primary"
            className={classes.mx1}
            onClick={() => CreateVehicleCancelClick()}
          >
            Cancel
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            className={classes.mx1}
            disabled={CreateVehicleControls.CreateVehicleSubmit}
            onClick={() => CreateVehicleSubmitClick()}
          >
            Submit
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  const VehicleInfoPopup = () => {
    return (
      <Grid
        className={classes.p3}
        id="vehicle-Info"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={`${classes.w100} ${classes.floatLeft} ${classes.mb4}`}>
          <Typography
            variant="h5"
            className={`${classes.font16} ${classes.semiBold} ${classes.floatLeft} ${classes.mb3}`}
          >
            Vehicle Details
          </Typography>
          <RACButton
            variant="contained"
            color="primary"
            className={`${classes.mx1} ${classes.floatRight}`}
            onClick={() => CreateVehicleClick()}
          >
            Create Vehicle
          </RACButton>
        </Grid>
        <Grid>
          {VehicleDetailsArray !== undefined ? (
            <RACTable
              className={`${classes.racGrid} ${classes.mb32}`}
              renderTableHead={VehcileHeadTable}
              renderTableContent={VehicleBodyTable}
            />
          ) : null}
        </Grid>
        <Grid>
          <Grid className={`${classes.pb4} ${classes.px3}`}></Grid>
          <RACButton
            variant="outlined"
            color="primary"
            className={classes.mx1}
            onClick={() =>
              setPopupOpen({ ...PopupOpen, VehicleInfoOpen: false })
            }
          >
            Cancel
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            className={classes.mx1}
            disabled={VehicleInfoInput.SubmitDisabled}
            onClick={() => VehicleInfoSubmitClick()}
          >
            Submit
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  const ProcessingFee = () => {
    return (
      <Grid>
        <Grid container className={classes.mb4}>
          <Typography className={`${classes.w50} ${classes.formLabel}`}>
            Processing Fee Amount
          </Typography>
          <Grid className={`${classes.w50} ${classes.floatLeft}`}>
            <RACTextbox
              isCurrency={true}
              value="0"
              className={'text'}
              dollarTextClassName={'dollar'}
              digitFormat="currency"
              disabled={true}
              OnChange={(e) => console.log(e)}
            />
          </Grid>
        </Grid>

        <Grid className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            variant="contained"
            color="primary"
            className={classes.mx1}
          >
            {' '}
            Override Amount
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  //PS_AD-1 below function will return all the div tags for auto pay popup
  const AutopayPopupup = () => {
    return (
      <Grid>
        {AutopayCustomerInfo.CustomerPhoneNumber !== '' ? (
          <Grid>
            <Grid className={autoPayClassName.autoPayContainer}>
              <Grid container>
                <Grid
                  item
                  md={12}
                  className={`${autoPayClassName.autoPayTitle} ${classes.textCenter} ${classes.mb3}`}
                >
                  Discuss the benefits of Autopay and enroll your customer today
                </Grid>

                <Grid
                  container
                  className={`${classes.mb3} ${classes.justifyCenter}`}
                >
                  <Grid item lg={10}>
                    <Grid xs="auto" className={classes.floatLeft}>
                      <Typography
                        className={`${autoPayClassName.autoPayValue} ${classes.me2}`}
                      >
                        1
                      </Typography>
                    </Grid>
                    <Grid xs="auto" className={classes.floatLeft}>
                      <Typography
                        variant="caption"
                        className={`${autoPayClassName.autoPaySubTitle} ${classes.w100} ${classes.floatLeft} ${classes.mb1}`}
                      >
                        Convenience
                      </Typography>
                      <Typography
                        variant="caption"
                        className={`${autoPayClassName.autoPaySubTitleAccent} ${classes.w100} ${classes.floatLeft}`}
                      >
                        Save Customer Time
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  container
                  className={`${classes.mb3} ${classes.justifyCenter}`}
                >
                  <Grid item lg={10}>
                    <Grid xs="auto" className={classes.floatLeft}>
                      <Typography
                        className={`${autoPayClassName.autoPayValue} ${classes.me2}`}
                      >
                        2
                      </Typography>
                    </Grid>
                    <Grid xs="auto" className={classes.floatLeft}>
                      <Typography
                        variant="caption"
                        className={`${autoPayClassName.autoPaySubTitle} ${classes.w100} ${classes.floatLeft} ${classes.mb1}`}
                      >
                        Zero Fees
                      </Typography>
                      <Typography
                        variant="caption"
                        className={`${autoPayClassName.autoPaySubTitleAccent} ${classes.w100} ${classes.floatLeft}`}
                      >
                        Avoid convenience/phone fees{' '}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  className={`${autoPayClassName.autoPayFormCheck} ${classes.mb3}`}
                >
                  <RACCheckBox
                    className={`${classes.floatLeft} ${classes.textCenter}`}
                    onClick={() => AutopayChkboxAction()}
                    checked={AutopayInput.AutopayChkBox}
                    label="Customer has verbally consented to receive this text message"
                  />
                </Grid>
                <Typography
                  className={`${autoPayClassName.autoPayPrimaryRenter} ${classes.my3}`}
                >
                  Autopay will be associated with the Primary Renter
                </Typography>
                <Grid container className={autoPayClassName.justifycenter}>
                  <Grid item md={8}>
                    <Grid
                      className={`${classes.w100} ${classes.floatLeft} ${classes.mb2}`}
                      style={{ width: '400px' }}
                    >
                      <Typography
                        variant="caption"
                        className={`${classes.me3} ${autoPayClassName.autoPayTxtStyle}`}
                      >
                        {' '}
                        Customer Name
                      </Typography>
                      <Typography
                        variant="caption"
                        className={autoPayClassName.autoPayLabelTxt}
                      >
                        {' '}
                        {AutopayCustomerInfo.CustomerName}
                      </Typography>
                    </Grid>
                    <Grid className={`${classes.w100} ${classes.floatLeft}`}>
                      <Typography
                        variant="caption"
                        className={`${classes.me3} ${autoPayClassName.autoPayTxtStyle}`}
                      >
                        Phone Number
                      </Typography>
                      <Typography
                        variant="caption"
                        className={autoPayClassName.autoPayLabelTxt}
                      >
                        {' '}
                        {AutopayCustomerInfo.CustomerPhoneNumber}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              md={12}
              className={`${classes.py4} ${classes.textCenter}`}
            >
              <RACButton
                variant="outlined"
                color="primary"
                className={classes.mx1}
                onClick={() => AutoPayNotnowClick()}
              >
                Not now
              </RACButton>
              <RACButton
                variant="contained"
                color="primary"
                className={classes.mx1}
                disabled={AutopayInput.AutoPaySubmitDisable}
                onClick={() => AutoPaySubmitClick()}
              >
                Send Text at Payment
              </RACButton>
            </Grid>
          </Grid>
        ) : (
          <Grid>
            <Grid>
              <Grid className={classes.textCenter}>
                <Typography className={classes.formLabel}>
                  {' '}
                  The TXT to Autopay signup functionality is not available
                  without a Cell Phone #
                </Typography>
                <Typography className={classes.formLabel}>
                  {' '}
                  Please add a Cell Phone # before attempting to send an AutoPay
                  signup text
                </Typography>
              </Grid>
              <Grid
                item
                md={12}
                className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
              >
                <RACButton
                  className={classes.mx1}
                  variant="contained"
                  color="primary"
                  onClick={() => AutoPayNotnowClick()}
                >
                  Ok
                </RACButton>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  };
  const IntialPayment = () => {
    return (
      <div
        id="initialpayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={classes.textCenter}>
          <Typography className={classes.mb4}>
            Do you want to take initial payment?
          </Typography>
        </Grid>
        <Grid className={`${classes.pb4} ${classes.textCenter}`}>
          <RACButton
            variant="outlined"
            color="primary"
            className={classes.mx1}
            onClick={() => InitialPaymentNoBtnclick()}
          >
            No
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            className={classes.mx1}
            onClick={() => InitialPaymentOnclick()}
          >
            Yes
          </RACButton>
        </Grid>
      </div>
    );
  };
  const PromotionNotAvailablePopup = () => {
    return (
      <Grid className="">
        <Grid className={classes.textCenter}>
          <Typography>
            The Promo code '{SalesLeadPromo}' has expired.{' '}
            <Typography className={`${classes.mt2} ${classes.fontBold}`}>
              Would you like to choose another promo?
            </Typography>
          </Typography>
        </Grid>
        <Grid className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            variant="outlined"
            color="primary"
            onClick={() =>
              history.push({
                pathname: `/dashboard`,
              })
            }
          >
            No
          </RACButton>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => {
              setSalesLeadPromo(undefined)
              setPromoErrorOpen(false)
            }}
          >
            Yes
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  const ConfirmDueDateCloseClick = () => {
    setPopupOpen({ ...PopupOpen, ConfirmDueDateOpen: false });
    setNextBtnClicked(false);
  };
  const customerSignatureCompletedFn = () => {
    setPopupOpen({ ...PopupOpen, InitialPaymentOpen: true });
    setPopupCompleteStatus({
      ...PopupCompleteStatus,
      CustomerSignComplete: true,
    });
    if (resumebtnFeatureFlag == '1' && featureFlagDetails?.SundaySky == '1') {
      const customerInfo = AgrCustomerObj.filter(
        (obj: any) => obj.priority == 1
      );
      if (customerInfo.length > 0) {
        const customerNameArray = customerInfo[0].customerName.split(' ');
        const getPrintReq: any = {
          identifier: AgreementId,
          identifierType: 'AGR',
          customerFirstName: customerNameArray[0],
          customerLastName: customerNameArray[1],
          emailId: customerInfo[0].emailId,
          documentStatus: 'signed',
          actionType: 'sundaySky',
          agreementNumber: AgreementNumber,
        };
        const sendEpoReq = {
          customerId: String(customerInfo[0].customerId),
          agreementId: String(AgreementId),
          emailId: customerInfo[0].emailId,
          phoneNumber: String(customerInfo[0].phoneNumber),
          isSendMail: false,
          sendEmailType: 'SSKYES',
        };
        Promise.all([getPrintDocument(getPrintReq), sendEPO(sendEpoReq)]);
      }
    }
  };
  const emailcompletedFn = () => {
    setPopupOpen({ ...PopupOpen, InitialPaymentOpen: true });
    setPopupCompleteStatus({
      ...PopupCompleteStatus,
      CustomerSignComplete: true,
    });
  };
  const customerSignatureCanceledFn = () => {
    setPopupOpen({ ...PopupOpen, CustomerSignOpen: false });
  };

  const storeExceptionCancelledFn = async () => {
    setStoreExceptionEnable(false)
    // setTwoFactorCancelClick(true)

  }
  const storeExceptionCompletedFn = async () => {
    debugger
    setStoreExceptionEnable(false)

    if (StoreExceptiontype.sacPriceEdited == true) {
      StoreExceptiontype = { ...StoreExceptiontype, sacPriceEdited: false }
      setStoreExceptiontype(StoreExceptiontype)
      if (StoreExceptiontype.competitorChecked == true || StoreExceptiontype.agrRateorTermEdited == true) {
        if (StoreExceptiontype.competitorChecked == true) {
          storeExceptionEnable = true
          setStoreExceptionEnable(storeExceptionEnable)
          // StoreExceptiontype = { ...StoreExceptiontype, competitorChecked: false }
          // setStoreExceptiontype(StoreExceptiontype)
        }
        if (StoreExceptiontype.agrRateorTermEdited == true) {
          storeExceptionEnable = true
          setStoreExceptionEnable(storeExceptionEnable)
          // StoreExceptiontype = { ...StoreExceptiontype, agrRateorTermEdited: false }
          // setStoreExceptiontype(StoreExceptiontype)

        }
      }
      else {
        storeExceptionOpened = false
        setstoreExceptionOpened(storeExceptionOpened)
        if (AutoPayToggleStatus !== true && !ItemSearchValues?.isExchangeAgreement) {
          setPopupOpen({
            ...PopupOpen,
            ConfirmDueDateOpen: false,
            AutoPayOpen: true,
          });
        } else {
          setPopupOpen({
            ...PopupOpen,
            ConfirmDueDateOpen: false,
            CustomerSignOpen: true,
          });
        }
      }

    }
    else if (StoreExceptiontype.agrRateorTermEdited == true || StoreExceptiontype.competitorChecked == true) {

      if (StoreExceptiontype.competitorChecked == true) {
        StoreExceptiontype = { ...StoreExceptiontype, competitorChecked: false }
        setStoreExceptiontype(StoreExceptiontype)

      }
      if (StoreExceptiontype.agrRateorTermEdited == true) {
        StoreExceptiontype = { ...StoreExceptiontype, agrRateorTermEdited: false }
        setStoreExceptiontype(StoreExceptiontype)

      }
      storeExceptionOpened = false
      setstoreExceptionOpened(storeExceptionOpened)
      if (AutoPayToggleStatus !== true && !ItemSearchValues?.isExchangeAgreement) {
        setPopupOpen({
          ...PopupOpen,
          ConfirmDueDateOpen: false,
          AutoPayOpen: true,
        });
      } else {
        setPopupOpen({
          ...PopupOpen,
          ConfirmDueDateOpen: false,
          CustomerSignOpen: true,
        });
      }
    }
    else {
      storeExceptionOpened = false
      setstoreExceptionOpened(storeExceptionOpened)
      if (AutoPayToggleStatus !== true && !ItemSearchValues?.isExchangeAgreement) {
        setPopupOpen({
          ...PopupOpen,
          ConfirmDueDateOpen: false,
          AutoPayOpen: true,
        });
      } else {
        setPopupOpen({
          ...PopupOpen,
          ConfirmDueDateOpen: false,
          CustomerSignOpen: true,
        });
      }
    }


  }

  const setTwoFactorCancel = () => {
    setTwoFactorAuth(false);
    setProceedButtonLoader(false);
    setCappedFreeTimePopup(false);
  }

  const daysExtenionValidation = async () => {
    const payload = {
      cappedFreeTimeValues: cappedFreeTimeValues, 
      cappedFreeTimeResponseValues: cappedFreeTimeResponseValues
    }
    const validationResponse = await cappedFreeTimeValidation(payload);
    console.log(validationResponse, 'validationResponse', InitialPaymentValues?.extensionAmount, validationResponse.usedFreeTimeAmount + parseFloat(InitialPaymentValues?.extensionAmount));
    if((validationResponse.allowdedFreeAmount < (validationResponse.usedFreeTimeAmount + parseFloat(InitialPaymentValues?.extensionAmount))) && parseFloat(InitialPaymentValues?.extensionAmount) > 0 && !agreementId && cappedFreeTimeAuthenticated){
      setCappedFreeTimePopup(true);
    }
    else{
      NextBtnClick();
    }
    console.log('InitialPaymentValues', InitialPaymentValues);
  }

  const freeTimeAuthPopupContent = () => {
    return(
      <Grid className={`${classes.textCenter}`}>
        <Alerticon/>
        <Typography className={`${classes.spacerMT3} ${classes.customerRacpopup1}`}>
          Allocated free time for this store has been exceeded. Do you still want to proceed ? 
        </Typography>
        <Grid className={`${classes.buttonsPadding}`}>
          <RACButton
            variant="outlined"
            color="primary"
            className={`${classes.floatLeft}`}
            onClick={() => {
              setCappedFreeTimePopup(false);
              setProceedButtonLoader(false);
            }}
          >
            Cancel
          </RACButton>
          <RACButton
            color="primary"
            variant="contained"
            className={`${classes.floatRight}`}
            onClick={() => {  
              setProceedButtonLoader(true);
              setTwoFactorAuth(true);
            }}
            loading={proceedButtonLoader} 
          >
            Proceed
          </RACButton>
        </Grid>
      </Grid>
    )
  }
  return (
    <Grid>
      {HiddenLoader === true ? <HideLoader /> : null}



      {storeExceptionEnable ?
        (
          <StoreException
            identifierId={agreementId ? Number(agreementId) : Number(initialAgrId)}
            isopen={storeExceptionEnable}
            setStoreExceptionEnable={setStoreExceptionEnable}
            refCode={StoreExceptiontype.sacPriceEdited == true ? 'CPO' : StoreExceptiontype.competitorChecked == true ? 'CPOA' : 'CRT'}
            identifierType={'AGREEMENT'}
            exceptionDescscription={StoreExceptiontype.sacPriceEdited == true ? 'Cash Price Override' : StoreExceptiontype.competitorChecked == true ? 'Competitor Price Override On Agreement' : 'Changed Rate or Term on Agreement'}
            storeExceptionCancelled={storeExceptionCancelledFn}
            storeExceptionCompleted={storeExceptionCompletedFn}
          />
        ) : null
      }
      <RACModalCard
        isOpen={PromoErrorOpen}
        TitleVariantClassName={classes.popupTitle}
        maxWidth="xs"
        children={PromotionNotAvailablePopup()}
        borderRadius={'20px !important'}
      />
      <RACModalCard
        isOpen={CreateAgrProcessingPopup}
        closeIcon={false}
        maxWidth="xs"
        children={CreateAgreementProcessingPopup()}
        borderRadius={'20px !important'}
      />
      <RACModalCard
        isOpen={CreateAgrFailPopup}
        closeIcon={false}
        maxWidth="xs"
        children={CreateAgreementFailPopup()}
        borderRadius={'20px !important'}
      />
      <RACModalCard
        isOpen={SomethingWentWrongOpen}
        closeIcon={false}
        maxWidth="xs"
        children={SomethingWentWrongPopup()}
        borderRadius={'20px !important'}
      />
      <RACModalCard
        isOpen={ErrorMsgPopupOpen}
        closeIcon={false}
        maxWidth="xs"
        children={ErrorMsgPopup()}
        borderRadius={'20px !important'}
      />

      <RACModalCard
        isOpen={PopupOpen.ConfirmDueDateOpen}
        title="Confirm Due Date"
        TitleVariantClassName={classes.popupTitle}
        closeIcon={true}
        onClose={() => ConfirmDueDateCloseClick()}
        maxWidth="xs"
        children={ConfirmDueDate()}
        borderRadius={'20px !important'}
      />

      <RACModalCard
        isOpen={PopupOpen.AutoPayOpen}
        closeIcon={
          AutopayCustomerInfo.CustomerPhoneNumber !== '' ? true : false
        }
        onClose={() => AutoPayCloseClick()}
        maxWidth="sm"
        children={AutopayPopupup()}
        borderRadius={'20px !important'}
      />

      <RACModalCard
        isOpen={PopupOpen.CreateVehicleOpen}
        closeIcon={true}
        maxWidth="sm"
        title="Create Vehicle"
        children={CreateVehiclePopup()}
        onClose={() => {
          setPopupOpen({ ...PopupOpen, CreateVehicleOpen: false });
        }}
        borderRadius={'20px !important'}
      />

      <RACModalCard
        isOpen={PopupOpen.VehicleInfoOpen}
        closeIcon={true}
        maxWidth="sm"
        title="Vehicle Information"
        children={VehicleInfoPopup()}
        onClose={() => {
          setPopupOpen({ ...PopupOpen, VehicleInfoOpen: false });
        }}
        borderRadius={'20px !important'}
      />
      <RACModalCard
        isOpen={PopupOpen.ProcessingFeePopupOpen}
        closeIcon={true}
        onClose={() =>
          setPopupOpen({ ...PopupOpen, ProcessingFeePopupOpen: false })
        }
        maxWidth="xs"
        children={ProcessingFee()}
        borderRadius={'20px !important'}
      />

      <RACModalCard
        isOpen={PopupOpen.InitialPaymentOpen}
        closeIcon={true}
        onClose={() => InitialPaymentCloseClick()}
        maxWidth="xs"
        children={IntialPayment()}
        borderRadius={'20px !important'}
      />
      {PopupOpen.CustomerSignOpen ? (
        <CustomerSignature
          customerSignUsingField="agreementDetails"
          customerSignUsingModule="rental"
          identifier={parseInt(AgreementId)}
          customersArr={AgrCustomerObj}
          manualSignOptions={ManualSignOptions}
          documentTypeArr={[]}
          customerSignatureCompletedFn={() => customerSignatureCompletedFn()}
          customerSignatureCanceledFn={() => customerSignatureCanceledFn()}
          emailcompletedFn={() => emailcompletedFn()}
          documentGenerateCompleted={documentGenerateCompleted}
        />
      ) : null}
      {SomethingWentWrong === true ? (
        <div
          className="Norecords"
          style={{ marginTop: '20%', marginLeft: '40%', fontWeight: 'bold' }}
        >
          <div style={{ float: 'left' }}>
            <Alerticon></Alerticon>
          </div>
          <div style={{ float: 'left', margin: '1% 0 0 0.5%' }}>
            Something went wrong!
          </div>
        </div>
      ) : (
        <Grid className={classes.px2}>
          <GeneralInformation
            exchangeProps={{isExchangeAgreeement : ItemSearchValues.isExchangeAgreement ,exchangeAgreementId : ItemSearchValues.exchangeAgreementId, exchangeAgreementRentPaid: ItemSearchValues?.addedItem?.agreementPricing?.rentPaid }}
            ref={childCompRef}
            PaymentScheduleValues={PaymentScheduleValues}
            CustomerCoverageData={CustomerCoverageData}
            IsPendingAgreement={IsPendingAgreement}
            PendingAgreementOptions={PendingAgreementOptions}
            PendingAgreementDropdownValues={PendingAgreementDropdownValues}
            settempSchedule={settempSchedule}
            DeliveryChargeDisable={DeliveryChargeDisable}
            DeliveryChargeFeeValues={DeliveryChargeFeeValues}
            EditDisablemode={EditDisablemode}
            setExtAmt={setExtAmt}
            PopupOpen={PopupOpen}
            AutoPayToggleStatus={AutoPayToggleStatus}
            setAutoPayToggleStatus={setAutoPayToggleStatus}
            TireAgreement={TireAgreement}
            PolicyEnabled={PolicyEnabled}
            setNextBtnDisabled={setNextBtnDisabled}
            PayScheduleValues={PayScheduleValues}
            RecalculateClicked={RecalculateClicked}
            CalenderDuedate={CalenderDuedate}
            AgreementSchedule={AgreementSchedule}
            EmployerPayschedule={EmployerPayschedule}
            CustomerID={CustomerID}
            StoreNumber={StoreNumber}
            GeneralInfoInputsChanged={GeneralInfoInputsChanged}
            throwStyle={classes}
            ClubStatus={ClubStatus}
            ClubOriginStore={ClubOriginStore}
            IsLdwTiedWithClub={IsLdwTiedWithClub}
            LDWstatus={LDWstatus}
            PromoCodeDropDownOptions={PromoCodeDropDownOptions}
            setPromoCodeDropDownOptions={setPromoCodeDropDownOptions}
            PromoCodeValue={PromoCodeValue}
            setPromoCodeValue={setPromoCodeValue}
            PromoCodeDisable={PromoCodeDisable}
            waiveLDWChecked={waiveLDWChecked}
            setWaiveLDWChecked={setWaiveLDWChecked}
            exchangeAgrDueDate={exchangeAgreementDueDate}
          />
          <PaymentCost
            exchangeProps={{isExchangeAgreeement : ItemSearchValues?.isExchangeAgreement, exchangeAgreementRentPaid: ItemSearchValues?.addedItem?.agreementPricing?.rentPaid, oldAgrTRTO: oldAgreementTRTO }}
            EditDisablemode={EditDisablemode}
            Openlogin={Openlogin}
            ExtAmt={ExtAmt}
            TireAgreement={TireAgreement}
            PolicyEnabled={PolicyEnabled}
            InitialPaymentValues={InitialPaymentValues}
            TotalPaymentValues={TotalPaymentValues}
            OtherFeeArray={OtherFeeArray}
            AgreementTotal={AgreementTotal}
            OtherAmtChanged={OtherAmtChanged}
            SacChanged={SacChanged}
            CurrentRole={CurrentRole}
            throwStyle={classes}
            PaymentCostLoading={PaymentCostLoading}
            setPaymentCostLoading={setPaymentCostLoading}
            RecalculateClicked={RecalculateClicked}
          />
          <Grid className={classes.fixedBottom}>
            <RACButton
              className={`${classes.mx1} ${classes.floatLeft}`}
              variant="outlined"
              color="primary"
              onClick={() => PreviousBtnClick()}
              disabled={PreviousBtnDisabled}
            >
              Previous
            </RACButton>
            {IsAgreementVoided !== true ? (
              <Grid style={{ float: 'right' }}>
                <RACButton
                  className={`${classes.me2} ${classes.floatRight}`}
                  variant="contained"
                  color="primary"
                  onClick={() => cappedFreeTimeValues?.enableCappedFreeTimeFlag ? daysExtenionValidation() : NextBtnClick()}
                  disabled={NextBtnDisabled}
                >
                  Next
                </RACButton>
              </Grid>
            ) : null}
            {IsAgreementVoided !== true && AgreementId == '' ? (
              <div style={{ float: 'right' }}>
                <AddCocustomer func={pulls_data} />
              </div>
            ) : null}
            {VoidEnabled === true &&
              IsAgreementVoided !== true &&
              InitialPaymentPaid === false ? (
              <VoidAgreement
                AgreementId={AgreementId}
                customerId={CustomerID}
                func={pull_data}
              />
            ) : null}
              {cappedFreeTimePopup ? (
                <RACModalCard
                  isOpen={cappedFreeTimePopup}
                  closeIcon={false}
                  borderRadius={"25px !important"}
                  onClose={() => setCappedFreeTimePopup(false)}
                  maxWidth={"xs"}
                  //eslint-disable-next-line react/no-children-prop
                  children={freeTimeAuthPopupContent()}
                />
              ) : null}
              {twoFactorAuth ? (
                <SecondFactor
                  setTwoFactorCancelClick={() => {
                    setTwoFactorCancel()
                  }}
                  setTwoFactorCompleted={(value) => {
                    if (value) {
                      NextBtnClick();
                      setCappedFreeTimeAuthenticated(false);
                    }
                  }}
                  moduleName={'CappedFreeTime'}
                  currentRole={CurrentRole}
                  setApprover={true}
                ></SecondFactor>
              ) : null}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
